import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faCar, faTree, faDollarSign, faMapMarkerAlt, faPhoneAlt, faBath } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import imagen1 from '../../assets/images/propiedad_1.jpg';
import imagen3 from '../../assets/images/propiedad_3.jpg';

const CardPropiedad = () => {
    return (
        <div className='md:w-1/3 md:mx-auto  my-2 sm:w-11/12'>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="h2" className='mb-2 pb-4'>
                        Dúplex - Valle Escondido
                    </Typography>
                    <Grid container spacing={1} className='mb-4'>
                        <Grid item xs={6} className='flex'>
                            <FontAwesomeIcon icon={faMapMarkerAlt} className='w-4' />
                            <Typography color="text.secondary" className='pl-4'>
                                 CÓRDOBA
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className='flex'>
                            <FontAwesomeIcon icon={faDollarSign} className='w-4' />
                            <Typography color="text.secondary" className='pl-4'>
                                 US$150.000
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className='flex'>
                            <FontAwesomeIcon icon={faBed} className='w-4' />
                            <Typography color="text.secondary" className='pl-4'>
                                3 Dormitorios
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className='flex'>
                            <FontAwesomeIcon icon={faBath} className='w-4' />
                            <Typography color="text.secondary" className='pl-4'>
                                2 Baños
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className='flex'>
                            <FontAwesomeIcon icon={faCar} className='w-4' />
                            <Typography color="text.secondary" className='pl-4'>
                                Cochera
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className='flex'>
                            <FontAwesomeIcon icon={faTree} className='w-4' />
                            <Typography color="text.secondary" className='pl-4'>
                                Patio
                            </Typography>
                        </Grid>
                    </Grid>

                    <Carousel showArrows={false} infiniteLoop={true} width={'full'} className='bg-gray-100'>
                        <div className="carousel-item h-72">
                            <img src={imagen1} className="w-full h-full object-cover" alt="Imagen 1" />
                        </div>
                        <div className="carousel-item h-72">
                            <img src={imagen3} className="w-full h-full object-cover" alt="Imagen 3" />
                        </div>
                    </Carousel>
                </CardContent>
            </Card>
        </div>
    );
}

export default CardPropiedad;
