import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { PROPIETARIOS, DELETE_PROPIETARIO } from '../../utils/apiUrls';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { convertirFecha } from '../../utils/funciones';
import TextField from '@mui/material/TextField';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import Swal from 'sweetalert2';
import EditIcon from '@mui/icons-material/Edit';
import AgregarPropietario from './AgregarPropietario';

const TablePropietarios = ({actualizar, setActualizar }) => {
  const [rows, setRows] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchValue, setSearchValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [openEdit, setOpenEdit] = useState(false)
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      cursor: 'pointer',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(PROPIETARIOS);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error en la solicitud');
        }
        const data = await response.json();
        setRows(data.data);
      } catch (error) {
        console.error('Error de solicitud:', error.message);
      }
    };

    fetchData();
    setSearchValue('')
  }, [actualizar]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    const filteredResults = rows.filter((row) =>
      row.nombre.toLowerCase().includes(searchValue) ||
      row.apellido.toLowerCase().includes(searchValue) ||
      row.direccion.toLowerCase().includes(searchValue) ||
      row.dni.toLowerCase().includes(searchValue)
    );
    setSearchValue(event.target.value);
    setSearchResults(filteredResults);
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setOpenModal(true);
  };

  const handleEditClick = (row) => {
    setEditId(row)
    setOpenEdit(true);
  };
  
  const handleDeleteConfirm = async () => {
    try {
      // Lógica para eliminar el propietario con el ID especificado en el frontend
      const updatedRows = rows.filter((row) => row.id_propietario !== deleteId);
      setRows(updatedRows);
      setOpenModal(false);
  
      // Aquí enviamos una solicitud para eliminar el propietario del backend
      const response = await fetch(DELETE_PROPIETARIO +`/${deleteId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          // Puedes incluir aquí cualquier otro encabezado necesario, como tokens de autorización, etc.
        },
      });
  
      if (!response.ok) {
        // Si la solicitud no es exitosa, lanzamos un error con el mensaje de la respuesta
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error al eliminar el propietario');
      } else {
          // Muestra una alerta de SweetAlert2
          setActualizar(!actualizar)
              Swal.fire({
                  title: 'Petición exitosa',
                  text: 'El PROPIETARIO se ha ELIMINADO correctamente.',
                  icon: 'success',
                });
        }
      // Si la eliminación en el backend es exitosa, puedes realizar cualquier otra acción necesaria, como mostrar un mensaje de éxito, etc.
    } catch (error) {
      console.error('Error al eliminar el propietario:', error.message);
      // Aquí puedes manejar el error de la manera que desees, como mostrar un mensaje de error al usuario, etc.
    }
  };

  const handleDeleteCancel = () => {
    setDeleteId(null);
    setOpenModal(false);
  };

  return (
    <>
      <div className=' flex-col w-full'>
        <div className='my-2'>
          <TextField
            label="Buscar"
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={handleSearchChange}
            className=" m-10 w-full"
          />
        </div>
        <div className="w-full" style={{ height: 'auto', maxHeight: '60vh', overflowY: 'auto' }}>
          <div style={{ overflowX: 'auto' }}>
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" onClick={() => handleSort('nombre')}>NOMBRE</StyledTableCell>
                    <StyledTableCell align="center" onClick={() => handleSort('apellido')}>APELLIDO</StyledTableCell>
                    <StyledTableCell align="center" onClick={() => handleSort('dni')}>DNI</StyledTableCell>
                    <StyledTableCell onClick={() => handleSort('direccion')}>Direccion</StyledTableCell>
                    <StyledTableCell align="center" onClick={() => handleSort('fecha_nacimiento')}>FECHA NACIMIENTO</StyledTableCell>
                    <StyledTableCell align="center">ELIMINAR</StyledTableCell>
                    <StyledTableCell align="center">EDITAR</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchValue === '' ? rows : searchResults).map((row) => (
                    <StyledTableRow key={row.id_propietario}>
                      <StyledTableCell align="center">{row.nombre}</StyledTableCell>
                      <StyledTableCell align="center">{row.apellido}</StyledTableCell>
                      <StyledTableCell align="center">{row.dni}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">{row.direccion}</StyledTableCell>
                      <StyledTableCell align="center">{convertirFecha(row.fecha_nacimiento)}</StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton onClick={() => handleDeleteClick(row.id_propietario)} color="error">
                          <PersonRemoveIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton onClick={() => handleEditClick(row)} color="secondary">
                          <EditIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      {/* Confirm Delete Dialog */}
      <Dialog open={openModal} onClose={handleDeleteCancel}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <p>¿Estás seguro de que deseas eliminar este propietario?</p>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDeleteConfirm} color="error">
            <p className='text-white font-medium '>Eliminar</p>
          </Button>
          <Button variant="contained" onClick={handleDeleteCancel} color="primary">
            <p className='text-white font-medium'>Cancelar</p>
          </Button>
        </DialogActions>
      </Dialog>
      {openEdit ? <AgregarPropietario  open={openEdit} setOpenModal={setOpenEdit} editPropietario={editId} actualizar={actualizar} setActualizar={setActualizar}  /> : null}
    </>
  );
};

export default TablePropietarios;
