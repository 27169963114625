// index.js (Archivo de entrada principal)
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'; // Importa el Router aquí
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Cambia la importación de @mui/styles a @mui/material/styles

const theme = createTheme({
  palette: {
    primary: {
      main: '#003366', // Color primario personalizado
    },
    secondary: {
      main: '#00CC66', // Color secundario personalizado
    },
  },});
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router basename='/'> 
      <App />
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
  
);
document.title = 'Chiappero Inmobiliaria';

