import React from 'react';
import {Typography, Container } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';

const Title = () => {
  
    return (
     <>
      <div className='flex-col w-full -mb-4 sm:mb-4'>
        <div className=' text-2xl font-semibold flex text-center'> 
          <div className='mx-auto flex'>
            <PaymentIcon className='mr-2 text-blue-600'/>
              <p className='-mt-1 text-blue-600'>
                PROPIEDADES
              </p>
          </div>
        </div>
            <Typography variant="body1" gutterBottom>
            <div className='flex w-full sm:hidden'>
              <p>
                Desde aquí podrá 
              </p>  
              <div className='text-blue-500 font-semibold px-1'> 
                VER Y HACER MODIFICACIONES 
              </div> 
              <p>
                sobre las propiedades...
              </p>
            </div> 
            </Typography>
      </div>
     </>
    );
  };
  export default Title;