import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from "@mui/material";
import { INMUEBLESPORVENCER } from "../../utils/apiUrls";
import { formatearFechaISO8601 } from "../../utils/funciones";

const ContratosVencer = () => {
  const [contratos, setContratos] = useState([]);

  useEffect(() => {
    // Fetch data from the backend
    fetch(INMUEBLESPORVENCER)
      .then(response => response.json())
      .then(data => setContratos(data));
  }, []);

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: 300, backgroundColor: "rgb(48, 108, 193)" }}
    >
      <Typography
        variant="h8"
        component="div"
        sx={{ flexGrow: 1, marginY: 1, color: "white" }}
      >
        Contratos a Vencer en los Próximos 60 Días
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: 240, marginTop: 0 }}>
        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 350 }}>
          <TableHead>
            <TableRow>
              <TableCell>Dirección</TableCell>
              <TableCell>Inicio</TableCell>
              <TableCell>Duración</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contratos.map((contrato, index) =>
              <TableRow
                key={contrato.id_contrato}
                sx={{
                  backgroundColor:
                    index % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "white",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)"
                  }
                }}
              >
                <TableCell sx={{ fontSize: 12 }}>
                  {contrato.direccion +
                    " " +
                    contrato.altura +
                    " - " +
                    contrato.piso +
                    " " +
                    contrato.unidad}
                </TableCell>
                <TableCell sx={{ fontSize: 12 }}>
                  {formatearFechaISO8601(contrato.fecha_inicio)}
                </TableCell>
                <TableCell sx={{ fontSize: 12 }}>
                  {contrato.duracion_contrato} Años
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TableContainer>
  );
};

export default ContratosVencer;
