import React, { useState, useEffect } from 'react';
import { Button, Modal, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import {ADD_INQUILINO} from '../../utils/apiUrls'
import {BUSCARINQUILINO} from '../../utils/apiUrls'
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs'; // Importa dayjs
import TablaInquilino from './TablaInquilinos';
import NuevoInquilino from './NuevoInquilino';
import AddIcon from '@mui/icons-material/Add';

const SeleccionarInquilino = ({ open, setOpenModal, inmueble, actualiza, setActualiza }) => {
    const [openModal, setOpenNuevoInquilino] = useState(false)
    const [inquilino, setInquilino] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(BUSCARINQUILINO + '/' + inmueble.id);
    
            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.message || 'Error en la solicitud');
            }
            const data = await response.json();
             setInquilino(data.data[0]);
             if (data.data[0]) {
              setOpenNuevoInquilino(true)
             }
          } catch (error) {
            console.error('Error de solicitud:', error.message);
          }
        };
    
        fetchData();
      }, []);

   
  const handleAgregarInquilino = () => {
    setOpenNuevoInquilino(true)
  }


  const handleClose = () => {
    setOpenModal(false);
  };
  
   return (
      
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
      <Box
        sx={inquilino ? null : {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "1050px",
          height: "auto",
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 1,
        }}
        style={{ maxHeight: '97%', overflow: 'auto' }}
      >


            {inquilino ?  
                <NuevoInquilino open={openModal}  setOpenModal={setOpenNuevoInquilino} inmueble={inmueble} openOld={open} setOpenOld={setOpenModal} cambiarinquilino={true} inquilino={inquilino} setInquilino={setInquilino} />
              : 
            <>
            <div className='w-full text-xl font-bold text-center py-2 text-white bg-blue-500 mb-2'>
                INQUILINOS 
            </div>


              <div className='text-end mr-4'>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    // Aquí puedes agregar la lógica para manejar el evento onClick
                    handleAgregarInquilino()
                  }}
                >
                  AGREGAR NUEVO INQUILINO
                </Button>
                
              </div>
              
              <TablaInquilino  inmueble={inmueble} actualiza={actualiza} setActualiza={setActualiza} closeModal={handleClose}  />
              <Divider/>
              <NuevoInquilino open={openModal}  setOpenModal={setOpenNuevoInquilino} inmueble={inmueble} cambiarinquilino={false} openOld={open} setOpenOld={setOpenModal} actualiza={actualiza} setActualiza={setActualiza} />
            </>

            }
          
        </Box>
      </Modal>

    </>
  );
};

export default SeleccionarInquilino;