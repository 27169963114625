import React, { useState, useEffect } from 'react';
import { Button, Modal, Box } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { BUSCAR_ALQUILERES_RENDIDOS,BUSCAR_GASTOS_RENDICION,BUSCAR_REINTEGROS_RENDICION, ELIMINAR_RENDICION, BUSCAR_CANTIDAD_RENDICIONES} from '../../utils/apiUrls'
import { PDFDownloadLink } from '@react-pdf/renderer';
import MyDocument from './PdfRendicion';
import { formatearMonedaARS, formatearFechaISO8601, sumarImportes, convertirFecha } from '../../utils/funciones';
import { useStyles } from '../../utils/funciones';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloseIcon from '@mui/icons-material/Close';
const ModalAgregarConsulta = ({setOpenModalRendicion,  openModalRendicion, open, setOpenModal, pendientes, fechaSeleccionada, propietario,setActualizarTabla, actualizarTabla }) => {
  const classes = useStyles();
  const [imprimePdf, setImprimePdf] = useState(false)
  const [gastos, setGastos] = useState(null); // Estado para almacenar la gastos encontrada
  const [totalGastos, setTotalGastos] = useState(0);
  const [montoInteresesSinFormatear, setMontoInteresesSinFormatear] = useState(0)
  const [actualizar, setActualizar] = useState(false)
  const [inmueble, setInmueble] = useState(null); // Estado para almacenar la gastos encontrada
  const [totalRendicionHonorarios, setTotalRendicionHonorarios] = useState(null);
  const [idRendicion, setIdRendicion] = useState(null)
  const [totalRendicion, setTotalRendicion] = useState(null);
  const [totalAlquiler, setTotalAlquiler] = useState(null);
  const [reintegros, setReintegros] = useState(null);
  const [totalReintegros, setTotalReintegros] = useState(null);
  const [multiRendiciones, setMultiRendiciones] = useState(null)
  const [idSeleccionado, setIdSeleccionado] = useState(null)
  console.log('desde modal', pendientes)
  useEffect(() => {
    console.log('multirendicion')
      const multirendicion = async () => {
        console.log('multirendicion')
        const response = await fetch(BUSCAR_CANTIDAD_RENDICIONES, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
              body: JSON.stringify({ id: propietario.id_propietario, fecha:fechaSeleccionada }),
        });
        if (response.ok) {
          const data = await response.json();
          //console.log('Gastos encontrados:', data);
          if (data.data.length > 0) {
            setMultiRendiciones(data.data);
            console.log('BUSCAR_CANTIDAD_RENDICIONES', data.data)
          } else {
            setMultiRendiciones(null)
          }
          // Sumar los montos de los gastos
        } else {
          console.error('Error al buscar la gastos:', response.status);
          setMultiRendiciones(null); 
        }
    }
    multirendicion()

  },[])

  useEffect(() => {
    const pedirdatos =async () => {
      console.log('pedirdatos')
      console.log(idSeleccionado)
        try {
            // Realizar la solicitud POST al servidor para buscar la gastos
            const response = await fetch(BUSCAR_ALQUILERES_RENDIDOS, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
                  body: JSON.stringify({ id: idSeleccionado, fecha:fechaSeleccionada }),
            });
            if (response.ok) {
              const data = await response.json();
              console.log('BUSCAR_ALQUILERES_RENDIDOS:', data);
              if (data.data.length > 0) {
                setInmueble(data.data);
                const response = await fetch(BUSCAR_GASTOS_RENDICION, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                      body: JSON.stringify({ id_propietario: propietario.id_propietario, fecha:fechaSeleccionada }),
                });

                if (response.ok) {
                  const data = await response.json();
                  console.log('Gastos encontrados:', data);
                  if (data.data.length > 0) {
                    setGastos(data.data);
                  } else {
                    setGastos(null)
                  }
                  const response2 = await fetch(BUSCAR_REINTEGROS_RENDICION, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                        body: JSON.stringify({ id_propietario: propietario.id_propietario, fecha:fechaSeleccionada }),
                  });
                  if (response2.ok) {
                    const data2 = await response2.json();
                    console.log('REINTEGROS encontrados:', data2);
                    if (data2.data.length > 0) {
                      setReintegros(data2.data);
                    } else {
                      setReintegros(null)
                    }
                    // Sumar los montos de los gastos
                  } else {
                    console.error('Error al buscar la gastos:', response.status);
                    setReintegros(null); 
                  }
                  // Sumar los montos de los gastos
                } else {
                  console.error('Error al buscar la gastos:', response.status);
                  setGastos(null); 
                }
              } else {
                setInmueble(null)
              }
            } else {
              console.error('Error al buscar la gastos:', response.status);
              setInmueble(null); 
            }
          } catch (error) {
            console.error('Error en la solicitud:', error);
          }
    }
    pedirdatos()
    
}, [ actualizar  ]);

useEffect(() => {
  let totalAlquiler = 0;
  let total = 0;

  // Sumar los montos de los gastos
  if (inmueble && Array.isArray(inmueble)) {
      inmueble.forEach(itemInmueble => {
          totalAlquiler += parseFloat(itemInmueble.alquiler_precio);
          total += parseFloat(itemInmueble.alquiler_precio);
      });
      
      setTotalAlquiler(totalAlquiler)
      let honorarios = ((total * propietario.honorarios) / 100) 
      setTotalRendicionHonorarios(honorarios)
      setTotalRendicion(total)

  }
  if (gastos && gastos.length > 0) {
      let totalGastos = 0
      gastos.forEach(itemGasto => {
          totalGastos+= parseFloat(itemGasto.importe);
          total -= parseFloat(itemGasto.importe)

      });
      setTotalGastos(totalGastos)
      setTotalRendicion(total)

  }
  if ( reintegros && reintegros.length > 0) {
      let totalReintegros = 0
      reintegros.forEach(item => {
          totalReintegros += parseFloat(item.importe);
          total += parseFloat(item.importe)
      });
      setTotalReintegros(totalReintegros)
      setTotalRendicion(total)

  }
}, [inmueble, actualizar, gastos, reintegros]);


  const handleDelete = async () =>{

    console.log(propietario)
    try {
        // Realizar la solicitud POST al servidor para buscar la gastos
        const response = await fetch(ELIMINAR_RENDICION, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
              body: JSON.stringify({ id: idSeleccionado }),
        });
        if (response.ok) {
            setActualizarTabla(!actualizarTabla)
            handleClose()
        } else {
          console.error('Error al buscar la gastos:', response.status);
          setGastos(null);

        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
}

  const handleClose = () => {
    console.log('aca')
    setOpenModal(!open)

};
const handlePdf = async (id) =>{
  console.log('handlePdf')
  console.log(id)

  setIdSeleccionado(id)
  setActualizar(!actualizar)
  setImprimePdf(!imprimePdf)

}
const handleRendir = () => {
  
  setOpenModalRendicion(!openModalRendicion)
  handleClose()
}

return (
    <div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width:  800,
                    height: 'h-full' ,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                }}
            >
            
            <div className={classes.card}>
              <div className='flex bg-green-600 '>
                <div className='flex mx-auto'>
                 <div className='w-full  bg-green-600 text-center text-white p-2 text-2xl'><BarChartIcon sx={{width:'35px', minHeight:'35px'}}/></div>
                 <div className='w-full  bg-green-600 text-center text-white p-2 text-2xl pt-3'>RENDICIONES</div>
                </div>
              </div>
                {imprimePdf ? 
                  <div className='flex-col text-center mt-2'>
                    <div className='mx-auto text-green-600 pt-4'>
                      <CheckCircleOutlineIcon style={{width:'50px', height:'50px'}}/> 
                    </div>
                    <div className='text-center p-2 pt-4 text-2xl text-green-600 font-semibold'>El RENDICION N° {idSeleccionado}  ESTA LISTA PARA DESCARGAR.</div>
                    
                  
                  </div>
                  

                  :
                  <>
                  {!pendientes ? 
                  <> 
                    <div className='text-center p-2 pt-4 text-2xl'>NO QUEDAN ALQUILERES DISPONIBLES PARA RENDIR.</div>
                    <div className='text-center p-2 text-xl'>SELECCIONE QUE DESEA HACER</div>
                    </>
                  : 
                  <>
                  <div className='flex-col flex'>
                    <div className='text-center p-2 pt-4 text-2xl flex mx-auto'>El propietario <p className='text-green-500 ml-2'>{propietario.nombre.toUpperCase() + ' ' + propietario.apellido.toUpperCase() }</p> </div>
                    <div className='text-center p-2 pt-4 text-2xl'>TIENE ALQUILERS PENDIENTES DE LIQUIDAR.</div>
                    <div className='text-center p-2 mb-12 mt-10 text-xl bg-blue-500 w-fit mx-auto rounded-md text-white cursor-pointer font-bold px-4' onClick={handleRendir}>RENDIR ALQUILERES</div>
                  </div>
                  {console.log(propietario)}
                  </>
                  }

                  </>                
                }

                <div className='flex py-4 min-w-full mx-auto text-center'>
                    <div className='mx-auto'>
                      {imprimePdf ? 
                      <>
                        <div className='flex-col items-center flex'>
                          <PDFDownloadLink document={<MyDocument reintegros={reintegros} totalReintegros={totalReintegros} totalGastos={totalGastos} totalRendicionHonorarios={totalRendicionHonorarios} honorarios={propietario.honorarios.toFixed(2)} idRendicion={idRendicion} handleClose={handleClose} open={open} fechaSeleccionada={fechaSeleccionada} totalRendicion={totalAlquiler} alquileres={inmueble} gastos={gastos} />} fileName={propietario.nombre + " "+ propietario.apellido + " " + fechaSeleccionada + ".pdf"}  >
                            {({ blob, url, loading, error }) => (
                              loading ? 
                              'Cargando documento...' : 
                              
                              <div className='p-3  w-full text-center  mx-auto bg-green-600 hover:bg-green-700 text-white font-semibold rounded-md flex  cursor-pointer' onClick={handleClose} >
                              <CloudDownloadIcon />
                                <div className='ml-2 text-center'>Descargar PDF</div>
                              </div>

                            )}
                          </PDFDownloadLink>
                          <div className='flex mt-16 w-full justify-between'>
                            <div className='mr-24 py-2 px-4  text-sm items-center bg-red-600 hover:bg-red-700 text-white font-semibold rounded-md flex cursor-pointer' onClick={handleDelete}>
                              <DeleteOutlineIcon/>
                              <p className='pl-2'>ELIMINAR</p>
                            </div>
                            <div className=' py-2 px-4  text-sm items-center bg-green-600 hover:bg-green-700 text-white font-semibold rounded-md flex cursor-pointer' onClick={handleClose}>
                              <CloseIcon/>
                              <p className='pl-2'>CERRAR</p>
                            </div>
                          </div>

                        </div>
                      </>
                      : 
                      <>
                      <div className='flex'>
                      {multiRendiciones && multiRendiciones.length > 0 && (
                          multiRendiciones.map((item, index) => {
                              return (
                                  <>  
                                    <div className='flex mb-2 bg-green-600 hover:bg-green-700 mx-2 h-10 items-center p-2  rounded-lg cursor-pointer'>
                                      <div>
                                          <PictureAsPdfIcon  sx={{color:'#FFF'}}/> 
                                      </div>
                                      <div key={index} className='ml-2 text-lg mx-auto text-white font-bold ' onClick={() => {handlePdf(item.id)}}>
                                      Rendicion N°: {' ' + item.id}
                                      </div>
                                  </div>
                                  </>
                              );
                          })
                      )} 
                      {!multiRendiciones ?        <div className='p-3 mx-auto mr-4 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-md flex  cursor-pointer' onClick={() => {handlePdf()}}>
                          <PictureAsPdfIcon className='mr-2'/> 
                          Imprimir PDF
                        </div> : null}
                        <div className='p-2   mb-2 mx-auto ml-2 text-sm items-center bg-red-600 hover:bg-red-700 text-white font-semibold rounded-md flex cursor-pointer' onClick={handleClose}>
                          <CloseIcon/>
                          
                          <p className='pl-2 pr-2 '>CERRAR</p></div>
                      </div>
                      </>
                      }
                    </div>
                    
                    
                </div>
           </div>
            </Box>
        </Modal>
    </div>
);
};

export default ModalAgregarConsulta;
