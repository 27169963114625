import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { INMUEBLES } from '../../utils/apiUrls';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { convertirFecha } from '../../utils/funciones';
import TextField from '@mui/material/TextField';
import Index from './Index'
import PaidIcon from '@mui/icons-material/Paid';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; // Importa dayjs
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ModalConsulta from './Consulta';


const BlueTable = () => {
  const [rows, setData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchValue, setSearchValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModalConsulta, setOpenModalConsulta] = useState(false);
  const [inmueble, setInmueble] = useState(false);
  const [fecha, setFecha] = useState(dayjs());
  const [actualizar, setActualizar] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#B60505',
      color: theme.palette.common.white,
      cursor: 'pointer',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
    
  const handleFecha = (date) => {
    // Convertir el objeto Date a dayjs antes de actualizar el estado
    setFecha(dayjs(date));
    setActualizar(!actualizar)
};
  useEffect(() => {
    // Realizar la solicitud fetch para obtener los datos desde el servidor
    const fetchData = async () => {
      try {
        const response = await fetch(INMUEBLES, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({fecha: fecha })
        });
    
        if (!response.ok) {
          // Si la respuesta no es exitosa, lanzamos un error con el mensaje de la respuesta
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error en la solicitud');
        }
    
        const data = await response.json();
        // Actualizamos el estado con los datos recibidos del servidor
        setData(data.data);
      } catch (error) {
        // Aquí puedes manejar los errores que puedan ocurrir durante la solicitud
        console.error('Error de solicitud:', error.message);
      }
    };
    

    fetchData();
  }, [actualizar, fecha]);

  const handleSort = (column) => {
    if (sortBy === column) {
      // Si hacemos clic en la misma columna, cambiamos el orden
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Si hacemos clic en una columna diferente, establecemos esa columna como la columna de ordenación
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const handleOpenModal = (row) => {
    setInmueble(row);
    setOpenModal(true);
  };
  
  const handleOpenModalConsulta = (row) => {
    setInmueble(row);
    setOpenModalConsulta(true);
  };
  


  // Ordenamos los datos si tenemos una columna de ordenación seleccionada
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value.toLowerCase());
  };
  
  // Filtrar los datos en función del valor de búsqueda actualizado
  const filteredData = rows.filter((row) => {
    const direccion = row.direccion?.toString();
    const piso = row.piso?.toString();
    const unidad = row.unidad?.toString();
    const altura = row.altura?.toString();
    const nombre = row.nombre?.toString();
    const apellido = row.apellido?.toString();
  
    const searchValueLower = searchValue;
    return (
      direccion?.toLowerCase().includes(searchValueLower) ||
      piso?.toLowerCase().includes(searchValueLower) ||
      unidad?.toLowerCase().includes(searchValueLower) ||
      altura?.toLowerCase().includes(searchValueLower) ||
      nombre?.toLowerCase().includes(searchValueLower) ||
      apellido?.toLowerCase().includes(searchValueLower)
    );
  });
  
  return (
   <>
    <div className=' flex-col w-full'>
      <div className='flex-col'>
      <div className=' items-end w-full flex -mt-10'>
          <div className='mr-2 mx-auto  mb-2 font-bold'>MES Y AÑO: </div>
          <LocalizationProvider size="small"  dateAdapter={AdapterDayjs}>
            <DateField 
                format="MM/YYYY" 
                size="small"
                sx={{ width: '160px' }}
                value={fecha} // Usa fecha convertida a dayjs
                onChange={(date) => handleFecha(date)}
                renderInput={(params) => (
                <TextField {...params} variant="standard" size="small" />
                )}
            />
          </LocalizationProvider>
        </div>
        <div className='my-2 w-full flex '>
            <TextField
              label="Buscar"
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={handleSearchChange}
              className=" m-10 w-full"
            />
        </div>
        

      
      </div>
      

      <div className="w-full" style={{ maxHeight: '60vh', overflowY: 'auto' }}>
        <div style={{ overflowX: 'auto' }}>
          
        <TableContainer component={Paper}>
          <Table  stickyHeader aria-label="sticky table">
            <TableHead >
                <TableRow >
                  <StyledTableCell onClick={() => handleSort('direccion')}><p className=' font-bold  text-base'>Direccion</p></StyledTableCell>
                  <StyledTableCell align="right" onClick={() => handleSort('nombre')}>
                    <p className=' font-bold  text-base'>Nombre</p>
                  </StyledTableCell>
                  <StyledTableCell align="right" onClick={() => handleSort('apellido')}>
                    <p className=' font-bold  text-base'>Apellido</p>
                  </StyledTableCell>
                  <StyledTableCell align="right" onClick={() => handleSort('dni')}>
                    <p className=' font-bold  text-base'>Dni</p>
                  </StyledTableCell>
                  <StyledTableCell align="right" onClick={() => handleSort('fecha_inicio')}>
                    <p className=' font-bold  text-base'>Inicio Contrato</p>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <p className=' font-bold  text-base'>Abonar</p>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row.direccion + ' ' + row.altura + ' - ' + row.piso + ' - ' + row.unidad}  {row.dias_diferencia < 65? <NotificationImportantIcon color='error'/> : null }
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.nombre}</StyledTableCell>
                    <StyledTableCell align="right">{row.apellido}</StyledTableCell>
                    <StyledTableCell align="right">{row.dni}</StyledTableCell>
                    <StyledTableCell align="right">{convertirFecha(row.fecha_inicio)}</StyledTableCell>
                    <StyledTableCell align="center"> 
                      {row.abono === 1 ?  <PictureAsPdfIcon color='error' className='hover:text-red-700 hover:cursor-pointer'  onClick={()=> handleOpenModalConsulta(row)}/>: 
                      <PaidIcon className='text-gray-500 hover:text-red-500 cursor-pointer' color='success' onClick={()=> handleOpenModal(row)} /> 
                      }
                    
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
    {openModal ? <Index fechaSeleccionada={fecha} setActualizarTabla={setActualizar} actualizarTabla={actualizar} open={openModal} setOpenModal={setOpenModal} inmueble={inmueble}  /> : null }
    {openModalConsulta ? <ModalConsulta fechaSeleccionada={fecha} setActualizarTabla={setActualizar} actualizarTabla={actualizar} open={openModalConsulta} setOpenModal={setOpenModalConsulta} inmueble={inmueble}  /> : null }
    
   </>
  );
};
export default BlueTable;
