import React from 'react';
import { FiMapPin, FiPhone, FiMail } from 'react-icons/fi';

function DondeEstamos() {
  return (
    <section className="bg-blue-900 text-white pt-6 h-fit">
      <div className="container mx-auto px-4 ">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="flex items-center justify-center flex-col ">
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3410.300405352834!2d-63.569861884883576!3d-32.052618681201546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942f2f19c3b78c95%3A0x8aa94c2198dabb0c!2sConstancio%20Giraudo%20365%2C%205980%20Oliva%2C%20C%C3%B3rdoba!5e0!3m2!1sen!2sar!4v1648426124544!5m2!1sen!2sar"
            width="400"
            height="150"
            className="sm:w-full sm:mx-auto"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>

          </div>
          <div className="flex items-center justify-center flex-col">
            <FiPhone className="text-5xl mb-4" />
            <h3 className="text-xl font-bold mb-2">Teléfono</h3>
            <p className="text-gray-300">03532 67-8233</p>
          </div>
          <div className="flex items-center justify-center flex-col">
            <FiMail className="text-5xl mb-4" />
            <h3 className="text-xl font-bold mb-2">Correo Electrónico</h3>
            <p className="text-gray-300">info@inmobiliaria.com</p>
          </div>
          <div className="flex items-center justify-center flex-col">
                <h3 className="text-2xl font-bold mb-4 md:text-center sm:text-center">Horario de Atención</h3>
                <ul className="text-gray-300  lg:text-start lg:pl-2 md:text-center sm:text-center">
                    <li className="mb-2">
                    <strong>Sábado:</strong> Cerrado
                    </li>
                    <li className="mb-2">
                    <strong>Domingo:</strong> Cerrado
                    </li>
                    <li className="mb-2">
                    <strong>Lunes a Viernes:</strong> 9 a.m.–12:30 p.m., 4–7 p.m.
                    </li>
                </ul>
          </div>



        </div>
       
      </div>
    </section>
  );
}

export default DondeEstamos;
