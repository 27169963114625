import React, { useState, useEffect } from 'react';
import { Button, Modal, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import {BUSCARPROPIETARIO} from '../../utils/apiUrls'
import TablaPropietarios from './TablaPropietarios';
import NuevoPropietario from './NuevoPropietario';

const SeleccionarPropietario = ({ open, setOpenModal, inmueble, actualiza, setActualiza }) => {
    const [openModal, setOpenNuevoPropietario] = useState(null)
    const [propietario, setpropietario] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(BUSCARPROPIETARIO + '/' + inmueble.id_propietario);
    
            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.message || 'Error en la solicitud');
            }
            const data = await response.json();
             setpropietario(data.data[0]);
             if (data.data[0]) {
              setOpenNuevoPropietario(true)
             }
          } catch (error) {
            console.error('Error de solicitud:', error.message);
          }
        };
    
        fetchData();
      }, []);

   
  const handleAgregarpropietario = () => {
    setOpenNuevoPropietario(true)
  }


  const handleClose = () => {
    setOpenModal(false);
  };
  
   return (
      
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
      <Box
        sx={propietario ? null : {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "1050px",
          height: "auto",
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 1,
        }}
        style={{ maxHeight: '97%', overflow: 'auto' }}
      >


            {propietario ?  
              <>

              <NuevoPropietario open={openModal}  setOpenModal={setOpenNuevoPropietario} inmueble={inmueble} openOld={open} setOpenOld={setOpenModal} actualiza={actualiza} setActualiza={setActualiza} modificar={true} setPropietarios={setpropietario}  />
              </>
              : 
            <>
            <div className='w-full text-xl font-bold text-center py-2 text-white bg-blue-500 mb-2'>
                PROPIETARIOS
            </div>
              <div className='text-center'>
                <span className='text-red-800 mr-4'>Si el PROPIETARIOS que buscas no esta en la tabla de abajo, se recomienda </span>
                <Button variant="outlined" color="success" onClick={() => handleAgregarpropietario()}>
                  AGREGAR NUEVO PROPIETARIO
                </Button>
              </div>
              <TablaPropietarios  inmueble={inmueble} actualiza={actualiza}  setActualiza={setActualiza} closeModal={handleClose}  />
              <Divider/>
              <NuevoPropietario open={openModal}  setOpenModal={setOpenNuevoPropietario} inmueble={inmueble} openOld={open} setOpenOld={setOpenModal} modificar={false} actualiza={actualiza} setActualiza={setActualiza} />
            </>

            }
          
        </Box>
      </Modal>

    </>
  );
};

export default SeleccionarPropietario;