import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { INMUEBLES_QUE_AUMENTAN } from '../../utils/apiUrls';
import { formatearFechaISO8601, formatearMonedaARS } from '../../utils/funciones';

const ContratosAumento = () => {
    const [contratos, setContratos] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    useEffect(() => {
        // Fetch data from the backend
        fetch(INMUEBLES_QUE_AUMENTAN)
            .then(response => response.json())
            .then(data => setContratos(data));
    }, []);

    const handleActualizarAlquiler = () => {
        setOpenModal(true);
    };

    return (
        <TableContainer component={Paper} sx={{ maxHeight: 580, backgroundColor: "rgb(48, 108, 193)" }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginY: 1, color: 'white' }}>
                Contratos con Aumento y Detalles del Inmueble
            </Typography>
            <TableContainer component={Paper} sx={{ maxHeight: 530, marginTop: 0 }}>
                <Table sx={{ minWidth: 500, font: 1 }} aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Dirección</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Alquiler</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Inicio</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Aumenta/Indice</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Alquiler</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contratos.map((contrato, index) => (
                            <TableRow
                                key={contrato.id_contrato}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.04)' : 'white',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    },
                                }}
                            >
                                <TableCell sx={{ fontSize: 12 }}>{contrato.direccion + ' ' + contrato.altura + ' - ' + contrato.piso + '  ' + contrato.unidad}</TableCell>
                                <TableCell sx={{ textAlign: 'center', fontSize: 12 }}>{formatearMonedaARS(contrato.alquiler_precio)}</TableCell>
                                <TableCell sx={{ textAlign: 'center', fontSize: 12, minWidth:91 }}>{formatearFechaISO8601(contrato.fecha_inicio)}</TableCell>
                                <TableCell sx={{ textAlign: 'center', fontSize: 12 }}>{contrato.tipo_aumento} Meses {contrato.indice_aumento}</TableCell>
                                <TableCell sx={{ textAlign: 'center', fontSize: 12 }}>
                                    <button onClick={handleActualizarAlquiler} className='bg-green-600 rounded p-1.5 text-white uppercase text-xs font-bold hover:bg-green-800'>
                                        Actualizar
                                    </button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </TableContainer>
    );
};

export default ContratosAumento;
