import React, { useState } from 'react';
import { Button, Modal, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import {ADD_SERVICIO} from '../../utils/apiUrls'
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';

const AgregarServicio = ({ open, setOpenModal, actualizar, setActualizar, editar, servicio}) => {
     
   const [newValue, setNewValue] = useState('');

   const handleChange = (event) => {
     const inputValue = event.target.value;
     setNewValue(inputValue);
     // Additional logic based on inputValue if needed
   };

  const handleClose = () => {
    setOpenModal(false);
  };
  
  const handleCargarInmuebleExitoso = () => {
    // Muestra una alerta de SweetAlert2
    setActualizar(!actualizar)
    handleClose();
    {editar ? 
        Swal.fire({
            title: 'Actualizacion exitosa',
            text: 'El servicio se ha ACTUALIZADO correctamente.',
            icon: 'success',
          })
        : 
        Swal.fire({
            title: 'Carga exitosa',
            text: 'El servicio se ha CREADO correctamente.',
            icon: 'success',
          });
    }
   
  }

  const handleGuardar = () => {
      // Realiza una solicitud POST a tu API
    fetch(ADD_SERVICIO, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({servicio:newValue}),
    })
      .then((response) => {
        console.log(response.ok)
        if (response.ok) {
            handleCargarInmuebleExitoso();
        } else {
          // La solicitud falló, maneja el error, por ejemplo, mostrando un mensaje de error
        }
      })
      .catch((error) => {
        // Maneja errores de red u otros errores
      });
  };

  
 
   return (
      
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "70%",
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
          }}
          style={{ maxHeight: '97%', overflow: 'auto' }}
        > 
        

            <div className='w-full text-xl font-bold text-center py-2 text-white bg-blue-500'>
                AGREGAR NUEVO GASTO

            </div>
            
            <div className='w-full'><Divider/></div>
            <div className='w-full flex text-center items-center '>

                <div className='mx-auto w-full mt-4'>
                    <div className='mx-auto  mt-4 w-full '>  </div>
                    <Divider color={'secondary'} />
                    <Divider color={'secondary'} />
                    <Divider color={'secondary'} />
                    <div className='pt-4  flex w-full mx-auto text-center items-center self-center '>
                      <div className='mx-auto my-6 w-72'>
                      <TextField
                          label="Ingrese el nombre del GASTO..."
                          id="standard-size-normal"
                          defaultValue=" "
                          variant="standard"
                          fullWidth
                          value={editar ? servicio : newValue}
                          onChange={handleChange} // Passing a function reference to handle change
                        />
                      </div>
                    </div>
                    <div className='mt-4'></div>
                    <div className='mt-4'>
                    <Divider color={'secondary'} />
                    <Divider color={'secondary'} />
                    <Divider color={'secondary'} />
                        <div className='mt-4 mb-2 text-center flex'>
                            <div className='mx-auto'>
                                <Stack spacing={2} direction="row">
                                    <Button  variant="contained" color='success' onClick={handleGuardar}> {editar ? 'Actualizar':'Guardar'} </Button>
                                    <Button variant="contained" color='error' onClick={handleClose}>Cancelar</Button>
                                </Stack>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
      </Modal>

    </>
  );
};

export default AgregarServicio;