import React, { useState, useEffect } from 'react';
import ContratosAumento from './ContratosAumentan';
import ContratosVencer from './ContratosVencer';
import Deudores from './DeudoresMes';
const Index = ({ open, setOpenModal, inmueble,  setActualizarTabla, actualizarTabla, fechaSeleccionada}) => {
  

    return (
        <div className='min-w-full mx-2 flex-col'>
            <div className='min-w-full flex'>
                <div className='w-7/12 mx-2'>
                    <ContratosAumento/>
                </div>
                <div className='w-5/12 mx-2 flex-col'>
                    <ContratosVencer/>
                    <Deudores/>
                </div>
            </div>
            <div className='mt-2'>
            </div>
        </div>
    );
};

export default Index;
