import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Pagos from './pages/Pagos';
import Propiedades from './pages/Propiedades';
import Rendiciones from './pages/Rendiciones';
import Index from './pages/Index';
import './index.css'; // Importa los estilos de CSS
import Propietarios from './pages/Propietarios'
import Edificios from './pages/Edificios'
import Home from './pages/Home';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);


  return (
    <Routes>
      <Route 
        path="/login" 
        element={<Login setIsAuthenticated={setIsAuthenticated} />} 
      />
      <Route
        path="/propietarios"
        element={isAuthenticated ? <Propietarios setIsAuthenticated={setIsAuthenticated}/> : <Login setIsAuthenticated={setIsAuthenticated} />}
      />
      <Route
        path="/pagos"
        element={isAuthenticated ? <Pagos setIsAuthenticated={setIsAuthenticated}/> : <Login setIsAuthenticated={setIsAuthenticated} />}
      />
      <Route
        path="/propiedades"
        element={isAuthenticated ? <Propiedades setIsAuthenticated={setIsAuthenticated}/> : <Login setIsAuthenticated={setIsAuthenticated} />}
      />
            <Route
        path="/rendiciones"
        element={isAuthenticated ? <Rendiciones setIsAuthenticated={setIsAuthenticated}/> : <Login setIsAuthenticated={setIsAuthenticated} />}
      />
      <Route
        path="/edificios"
        element={isAuthenticated ? <Edificios setIsAuthenticated={setIsAuthenticated}/> : <Login setIsAuthenticated={setIsAuthenticated} />}
      />
      <Route
        path="/home"
        element={isAuthenticated ? <Home setIsAuthenticated={setIsAuthenticated}/> : <Login setIsAuthenticated={setIsAuthenticated} />}
      />
        <Route
        path=""
        element={<Index/>}
      />
    </Routes>
  );
};

export default App;
