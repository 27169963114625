import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { PROPIETARIOS_RENDIR, BUSCAR_ALQUILERES_RENDIR } from '../../utils/apiUrls';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { convertirFecha } from '../../utils/funciones';
import TextField from '@mui/material/TextField';
import BarChartIcon from '@mui/icons-material/BarChart';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs'; // Importa dayjs
import ModalRendicion from './ModalRendicion'
import ModalConsulta from './ModalConsulta'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
const TablePropietarios = () => {
  const [rows, setData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchValue, setSearchValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModalRendicion, setOpenModalRendicion] = useState(false);
  const [rendicionesPendientes, setRendicionesPendientes] = useState(null);
  const [openModalConsulta, setOpenModalConsulta] = useState(false);
  
  const [propietario, setPropietario] = useState(false);
  const [fecha, setFecha] = useState(dayjs());
  const [actualizar, setActualizar] = useState(false)
  const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date())
 

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#4caf50',
      color: theme.palette.common.white,
      cursor: 'pointer',
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleFecha = (date) => {
    // Convertir el objeto Date a dayjs antes de actualizar el estado
    setFecha(dayjs(date));
    setFechaSeleccionada(new Date(date))
    setActualizar(!actualizar)
  };
  
  useEffect(() => {
    // Realizar la solicitud fetch para obtener los datos desde el servidor
    const fetchData = async () => {
      try {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Especificamos el tipo de contenido como JSON
            // Agrega aquí cualquier otra cabecera necesaria
          },
          body: JSON.stringify({ fecha: fechaSeleccionada }), // Convertimos los datos a JSON y los enviamos en el cuerpo de la solicitud
        };
    
        const response = await fetch(PROPIETARIOS_RENDIR, requestOptions);
    
        if (!response.ok) {
          // Si la respuesta no es exitosa, lanzamos un error con el mensaje de la respuesta
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error en la solicitud');
        }
    
        const data2 = await response.json();
        // Actualizamos el estado con los datos recibidos del servidor
        setData(data2.data);
        console.log(data2.data);
      } catch (error) {
        // Aquí puedes manejar los errores que puedan ocurrir durante la solicitud
        console.error('Error de solicitud:', error.message);
      }
    };
    fetchData();
  }, [ actualizar]);

  const handleSort = (column) => {
    if (sortBy === column) {
      // Si hacemos clic en la misma columna, cambiamos el orden
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Si hacemos clic en una columna diferente, establecemos esa columna como la columna de ordenación
      setSortBy(column);
      setSortOrder('asc');
    }
  };


  const handleSearchChange = (event) => {
    setSearchValue(event.target.value); // No necesitas convertir a minúsculas aquí
  };
  
  useEffect(() => {
    const filteredData = rows.filter((row) => {
      const nombre = row.nombre?.toString().toLowerCase(); // Mantén las variables de datos en su forma original
      const apellido = row.apellido?.toString().toLowerCase();
    
      const searchValueLower = searchValue.toLowerCase(); // Convierte el valor de búsqueda a minúsculas aquí
      return (
        nombre.includes(searchValueLower) || // Usa las variables de datos originales
        apellido.includes(searchValueLower) 
      );
    });
  }, [rows]);

  const filteredData = rows.filter((row) => {
    const nombre = row.nombre?.toString().toLowerCase(); // Mantén las variables de datos en su forma original
    const apellido = row.apellido?.toString().toLowerCase();
  
    const searchValueLower = searchValue.toLowerCase(); // Convierte el valor de búsqueda a minúsculas aquí
    return (
      nombre.includes(searchValueLower) || // Usa las variables de datos originales
      apellido.includes(searchValueLower) 
    );
  });
  
  const handleOpenModalRendicion = (row) =>{
    setPropietario(row)
    setOpenModalRendicion(!openModalRendicion)
  }

  const handleOpenModalConsulta = (row) =>{
    setPropietario(row)
    const fetchData = async () => {
      try {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Especificamos el tipo de contenido como JSON
            // Agrega aquí cualquier otra cabecera necesaria
          },
          body: JSON.stringify({ propietario: row, fecha: fechaSeleccionada }), // Convertimos los datos a JSON y los enviamos en el cuerpo de la solicitud
        };
    
        const response = await fetch(BUSCAR_ALQUILERES_RENDIR, requestOptions);
    
        if (!response.ok) {
          // Si la respuesta no es exitosa, lanzamos un error con el mensaje de la respuesta
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error en la solicitud');
        }
    
        const data2 = await response.json();
        // Actualizamos el estado con los datos recibidos del servidor
          console.log('BUSCAR_ALQUILERES_RENDIR')
          console.log(data2.data.length)
          setRendicionesPendientes(data2.data)

          setOpenModalConsulta(true)
      } catch (error) {
        // Aquí puedes manejar los errores que puedan ocurrir durante la solicitud
        console.error('Error de solicitud:', error.message);
      }
    };
    fetchData();
  }

  return (
   <>
    <div className=' flex-col w-full'>
    <div className='flex-col'>
      <div className=' items-end w-full flex -mt-10'>
          <div className='mr-2 mx-auto  mb-2 font-bold'>MES Y AÑO: </div>
          <LocalizationProvider size="small"  dateAdapter={AdapterDayjs}>
            <DateField 
                format="MM/YYYY" 
                size="small"
                sx={{ width: '160px' }}
                value={fecha ? fecha : null} // Usa fecha convertida a dayjs
                onChange={(date) => handleFecha(date)}
                renderInput={(params) => (
                <TextField {...params} variant="standard" size="small" />
                )}
            />
          </LocalizationProvider>
        </div>
        <div className='my-2 w-full flex '>
            <TextField
              label="Buscar"
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={handleSearchChange}
              className=" m-10 w-full"
            />
        </div>
        

      
      </div>
      <div className="w-full" style={{ maxHeight: '60vh', overflowY: 'auto' }}>
        <div style={{ overflowX: 'auto' }}>
          
        <TableContainer component={Paper}>
          <Table  stickyHeader aria-label="sticky table">
            <TableHead  >
                <TableRow >
                  <StyledTableCell  align="center" onClick={() => handleSort('nombre')}>
                    NOMBRE
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={() => handleSort('apellido')}>
                    APELLIDO
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={() => handleSort('dni')}>
                    DNI
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('direccion')}>
                    DIRECCION
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={() => handleSort('fecha_nacimiento')}>
                    FECHA NACIMIENTO
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={() =>{}}>
                    RENDICION
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row) => (
                  <StyledTableRow key={row.id_propietario}>
                    <StyledTableCell align="center">{row.nombre}</StyledTableCell>
                    <StyledTableCell align="center">{row.apellido}</StyledTableCell>
                    <StyledTableCell align="center">{row.dni}</StyledTableCell>
                    <StyledTableCell component="th" scope="row"> {row.direccion}  </StyledTableCell>
                    <StyledTableCell align="center">{convertirFecha(row.fecha_nacimiento)}</StyledTableCell>
                    <StyledTableCell align="center">
                        {row.rendicion ===1 ? 
                          <CheckCircleOutlineIcon className='hover:cursor-pointer' color='secondary' onClick={() => {row.rendicion == 1 ? handleOpenModalConsulta(row) : handleOpenModalRendicion(row)} } />  
                          :
                          <BarChartIcon
                          onClick={() => {row.rendicion == 1 ? handleOpenModalConsulta(row) : handleOpenModalRendicion(row)} } // Pasar la fila como argumento
                          className='hover:cursor-pointer'
                          color={row.rendicion == 1 ? 'secondary' : 'error'} 
                          />
                          
                        }
                    </StyledTableCell>                  
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
    {openModalRendicion ? <ModalRendicion open={openModalRendicion} setOpenModal={setOpenModalRendicion} propietario={propietario} setActualizarTabla={setActualizar} actualizarTabla={actualizar} fechaSeleccionada={fecha} setFechaSeleccionada={setFecha} /> : null}
    {openModalConsulta ? <ModalConsulta openModalRendicion={openModalRendicion} setOpenModalRendicion={setOpenModalRendicion} open={openModalConsulta} pendientes={rendicionesPendientes} setOpenModal={setOpenModalConsulta} propietario={propietario} setActualizarTabla={setActualizar} actualizarTabla={actualizar} fechaSeleccionada={fecha} setFechaSeleccionada={setFecha} /> : null}
    
   </>
  );
};
export default TablePropietarios;
