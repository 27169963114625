import React from 'react';
import {Typography, Container } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import BarChartIcon from '@mui/icons-material/BarChart';
const Title = () => {
    return (
      <>
       <div className='flex-col w-full'>
         <div className=' text-2xl font-semibold flex text-center'> 
           <div className='mx-auto flex'>
             <BarChartIcon className='mr-2 text-green-600'/>
               <p className='-mt-1 text-green-600'>
                 RENDICIONES
               </p>
           </div>
         </div>
             <Typography variant="body1" gutterBottom>
             <div className='flex w-full text-center'><p  className='flex  text'>Desde aca va a poder </p>  
             <p className=' text-green-500 font-semibold px-1'> VER Y REGISTRAR RENDICIONES </p> <p>de alquileres...</p></div> 
             </Typography>
       </div>
 
      </>
     );
  };
  export default Title;