import React from "react";
import { useState } from "react";
import Navar from "../utils/Navar";
import Title from "../components/edificios/Title";
import Index from "../components/edificios/index";
import { useStyles } from "../utils/funciones";

const Edificios = ({ setIsAuthenticated }) => {
  const classes = useStyles();
  const [name, setName] = useState('EDIFICIOS')
  return (
    <>
      <div className="bg-gray-100   flex">
        <Navar setIsAuthenticated={setIsAuthenticated} />
        <div className="w-full text-center flex-col mx-auto mt-20 -ml-20   ">
          <Title name={name} setName={setName}/>
          <Index  name={name} setName={setName}/>
        </div>
      </div>
    </>
  );
};

export default Edificios;
