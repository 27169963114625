import React, { useState, useEffect } from 'react';
import { Button, Modal, Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useStyles } from '../../utils/funciones';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs'; // Importa dayjs
import { DateField } from '@mui/x-date-pickers/DateField';
import { Avatar } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GavelIcon from '@mui/icons-material/Gavel';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import { CARGAR_ACTUALIZAR_CONTRATO, BUSCAR_CONTRATO } from '../../utils/apiUrls';
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';

const InsertarContratoModal = ({ open, setOpenModal, idPropiedad, actualiza, setActualiza, inmueble }) => {
  const classes = useStyles();
  const ariaLabel = { 'aria-label': 'description' };
  const [datosContratos, SetDatosContratos] = useState(null)
  const [actualizaContrato, SetActualizaContrato] = useState(false)
  const [formulario, setFormulario] = useState({
    inicioContrato: '',
    aniosContrato:  '',
    aumentosContrato: '',
    indiceContrato: '',
    tipoContrato:  '',
    montoPorcentaje:  0,
    id_propiedad: idPropiedad,
    id:  ''
});

const handleSave = (value) => {
  console.log(value)
  setFormulario({ ...formulario, inicioContrato: value });
};
const handleSave2 = (value) => {
  setFormulario({ ...formulario, montoPorcentaje: value.target.value });
};

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value)
    setFormulario({ ...formulario, [name]: value });
  };

  const handleClose = () => {
    setActualiza(true)
    setOpenModal(!open);
  };

    useEffect(() => {
      let fecha = new Date()
      const postData = async () => {
        try {
          const response = await fetch(BUSCAR_CONTRATO, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
              // Ajusta los encabezados según sea necesario
            },
            body: JSON.stringify({
              id_contrato: inmueble.id_contrato
              // Añade más campos según sea necesario
            })
          });
          
          if (response.ok) {
            const responseData = await response.json(); // Lee y parsea el cuerpo de la respuesta como JSON
            SetDatosContratos(responseData.data[0])
            setFormulario({
              inicioContrato: dayjs(responseData.data[0].fecha_inicio) || '',
              aniosContrato: responseData.data[0].duracion_contrato || '',
              aumentosContrato: responseData.data[0].tipo_aumento || '',
              indiceContrato: responseData.data[0].indice_aumento || '',
              tipoContrato: responseData.data[0].tipo_contrato || '',
              montoPorcentaje: responseData.data[0].porcentaje || '',
              id: responseData.data[0].id_contrato || null,
              id_propiedad: inmueble.id
            });

          } else {
            console.error('Error al enviar el formulario');
          }
        } catch (error) {
          console.error('Error al enviar el formulario:', error);
        }
      };
      postData();

    }, []);

    useEffect(() => {
      if (datosContratos) {
        
      }
    }, [datosContratos]);


  const handleGuardar = async () => {
    try {
      const response = await fetch(CARGAR_ACTUALIZAR_CONTRATO, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formulario)
      });
  
      if (!response.ok) {
        throw new Error('Error al realizar la solicitud');
      }
  
      const responseData = await response.json();
      if ( responseData.success) {
        console.log('Respuesta del servidor:', responseData);
        //actualizar la tabla
        setActualiza(!actualiza)
        handleClose()
      }

    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
    }
  };
  
  return (
 
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "60%",
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
          }}
          style={{ maxHeight: '90%', overflow: 'auto' }}
        > 
          <div className='min-h-full '>
            <div className={classes.card}>
              <div className='text-center bg-blue-900 text-white text-lg p-1'>CONTRATO</div>
            </div>
       
            <div className={classes.card}>
            <div className='pt-4 text-center text-black '>
              
              <div style={{ borderBottom: '1px solid #039be5'}} className='w-10/12 mx-auto flex'>
                <div className='flex mx-auto'>
                  <div><FmdBadOutlinedIcon sx={{color:'blue'}}/></div>
                  <div className=' text-lg text-center '>
                    {inmueble.direccion + " " + inmueble.altura + " " + inmueble.piso + " " + inmueble.unidad}
                  </div>
                </div>
              </div> {/* Línea */}

            </div>
              <div className='flex pt-10 mx-6'>
                <div className=' mx-auto w-1/5'>
                  <div className='flex pl-0 pt-2 my-2 mb-4 '>
                    <Avatar className={''} sx={{
                      width: '30px',
                      height: '30px',
                      boxShadow: '0px 0px 0px 3px #039be5',
                      borderRadius: '50%',
                      background:'transparent'
                      }}>
                        <CalendarMonthIcon color='success'/>
                    </Avatar>
                      <div>
                          <div style={{ borderBottom: '1px solid #039be5', paddingRight:'10px'}}><p className='pl-2'>Inicio Contrato</p></div> {/* Línea */}
                      </div>
                  </div>
                  <LocalizationProvider size="small" dateAdapter={AdapterDayjs}>
                    <DateField
                      format="DD/MM/YYYY"
                      size="small"
                      sx={{ width: '200px' }}
                      className="no-padding-textfield"
                      value={formulario.inicioContrato}
                      onChange={handleSave}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name='inicioContrato' // Aquí asigna el nombre al TextField
                          variant="standard"
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className=' mx-auto w-1/4'>
                  <div className='flex pl-0 pt-2 my-2 mb-4'>
                      <Avatar className={''} sx={{
                        width: '30px',
                        height: '30px',
                        boxShadow: '0px 0px 0px 3px #039be5',
                        borderRadius: '50%',
                        background:'transparent'
                        }}>
                          <WhereToVoteIcon color='success'/>
                      </Avatar>
                        <div>
                            <div style={{ borderBottom: '1px solid #039be5', paddingRight:'10px'}}><p className='pl-2 pr-2'>Años Contrato</p></div> {/* Línea */}
                        </div>
                    </div>
                  <FormControl sx={{ width:'100%'}} size="small">
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={formulario.aniosContrato}
                      onChange={handleSelectChange}
                      name='aniosContrato'
                      >
                      <MenuItem value={1}>1 Año</MenuItem>
                      <MenuItem value={2}>2 Años</MenuItem>
                      <MenuItem value={3}>3 Años</MenuItem>
                      <MenuItem value={4}>4 Años</MenuItem>
                      <MenuItem value={5}>5 Años</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className=' mx-auto w-1/4'>
                  <div className='flex pl-0 pt-4 mb-4'>
                      <Avatar className={''} sx={{
                        width: '30px',
                        height: '30px',
                        boxShadow: '0px 0px 0px 3px #039be5',
                        borderRadius: '50%',
                        background:'transparent'
                        }}>
                          <TrendingUpIcon color='success'/>
                      </Avatar>
                        <div>
                            <div style={{ borderBottom: '1px solid #039be5', paddingRight:'10px'}}><p className='pl-2 pr-8'>Aumentos</p></div> {/* Línea */}
                        </div>
                    </div>
                  <FormControl sx={{ width:'100%'}} size="small">
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={formulario.aumentosContrato}
                      label="aumentosContrato"
                      onChange={handleSelectChange}
                      name='aumentosContrato'

                      >
                      <MenuItem value={1}>1 Mes</MenuItem>
                      <MenuItem value={2}>2 Meses</MenuItem>
                      <MenuItem value={3}>3 Meses</MenuItem>
                      <MenuItem value={4}>4 Meses</MenuItem>
                      <MenuItem value={5}>5 Meses</MenuItem>
                      <MenuItem value={6}>6 Meses</MenuItem>
                      <MenuItem value={12}>12 Meses</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                
                
              </div>
              <div className='flex pb-10 pt-2 mx-6'>
              <div className=' mx-auto w-1/4'>
                  <div className='flex pl-0 pt-2 my-2 mb-4'>
                      <Avatar className={''} sx={{
                        width: '30px',
                        height: '30px',
                        boxShadow: '0px 0px 0px 3px #039be5',
                        borderRadius: '50%',
                        background:'transparent'
                        }}>
                          <GavelIcon color='success'/>
                      </Avatar>
                        <div>
                            <div style={{ borderBottom: '1px solid #039be5', paddingRight:'10px'}}><p className='pl-2 pr-16'>Indice</p></div> {/* Línea */}
                        </div>
                    </div>
                    <FormControl sx={{ width:'100%'}} size="small">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={formulario.indiceContrato}
                        label="indiceContrato"
                        onChange={handleSelectChange}
                        name='indiceContrato'
                        >
                        <MenuItem value={'IPC'}>IPC</MenuItem>
                        <MenuItem value={'ICL'}>ICL</MenuItem>
                        <MenuItem value={'PORCENTAJE'}>PORCENTAJE</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {formulario.indiceContrato  == 'PORCENTAJE' ? 
                  <div className=' mx-auto w-1/4'>
                  <div className='flex pl-0 pt-2 my-2 mb-4'>
                      <Avatar className={''} sx={{
                        width: '30px',
                        height: '30px',
                        boxShadow: '0px 0px 0px 3px #039be5',
                        borderRadius: '50%',
                        background:'transparent'
                        }}>
                          <PercentOutlinedIcon color='success'/>
                      </Avatar>
                        <div>
                            <div style={{ borderBottom: '1px solid #039be5', paddingRight:'10px'}}><p className='pl-2 pr-16'>Porcentaje</p></div> {/* Línea */}
                        </div>
                    </div>
                    <FormControl sx={{ width:'100%'}} size="small">
                      <TextField
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        size='small'
                        value={formulario.montoPorcentaje}
                        label="Porcentaje "
                        onChange={(e) => {handleSave2(e) }}
                        name='montoPorcentaje'
                      >

                      </TextField>
                    </FormControl>
                  </div>
                  :null}
              <div className=' mx-auto w-1/4'>
                  <div className='flex pl-0 pt-2 my-2 mb-4'>
                      <Avatar className={''} sx={{
                        width: '30px',
                        height: '30px',
                        boxShadow: '0px 0px 0px 3px #039be5',
                        borderRadius: '50%',
                        background:'transparent'
                        }}>
                          <MapsHomeWorkIcon color='success'/>
                      </Avatar>
                        <div>
                            <div style={{ borderBottom: '1px solid #039be5', paddingRight:'10px'}}><p className='pl-2 pr-16'>Tipo</p></div> {/* Línea */}
                        </div>
                    </div>
                    <FormControl sx={{ width:'100%'}} size="small">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={formulario.tipoContrato}
                        label="tipoContrato"
                        onChange={handleSelectChange}
                        name='tipoContrato'

                        >
                        <MenuItem value={'Vivienda'}>Vivienda</MenuItem>
                        <MenuItem value={'Comercial'}>Comercial</MenuItem>
                      </Select>
                    </FormControl>
                </div>
              </div>
              <div className='flex pb-10 mx-6'>
                <div className='mx-auto'>
                  <Stack spacing={2} direction="row">
                    <Button  variant="contained" color='success' onClick={handleGuardar}> {actualizaContrato ? 'ACTUALIZAR':'GUARDAR'} </Button>
                    <Button variant="contained" color='error' onClick={handleClose} >CANCELAR</Button>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
      </Box>
    </Modal>
    </>
  );
};

export default InsertarContratoModal;
