import React from 'react';
import {Typography, Container } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';

const Title = () => {
  
    return (
     <>
      <div className='flex-col w-full'>
        <div className=' text-2xl font-semibold flex text-center'> 
          <div className='mx-auto flex'>
            <PaymentIcon className='mr-2 text-red-600'/>
              <p className='-mt-1 text-red-600'>
                PAGOS
              </p>
          </div>
        </div>
            <Typography variant="body1" gutterBottom>
             <div className='flex w-full '>
                <p className='flex'>
                  Desde aca va a poder 
                </p>  
                <p className=' text-red-500 font-semibold px-1'> 
                  VER Y REGISTRAR LOS PAGOS 
                </p> 
                <p>
                  de alquileres...
                </p>
              </div> 
            </Typography>
      </div>

     </>
    );
  };
  export default Title;