import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { BUSCAR_GASTOS_RENDICION, ELIMINAR_GASTO, ACTUALIZAR_GASTO } from '../../utils/apiUrls';
import { formatearMonedaARS } from '../../utils/funciones';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import AgregarGastosModal from './AgregarGasto';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Checkbox from '@mui/material/Checkbox';

const TablaGastos = ({ setGastosDescontar, propietarios, setTotalInmuebles, inmueble, setInmueble, setFechaSeleccionada, fechaSeleccionada, setActualizaDatos, actualizaDatos, totalRendicionLocal, setTotalRendicionLocal }) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [gastos, setGastos] = useState(null);
  const [openModalAgregarGastos, setOpenModalAgregarGastos] = useState(false);
  const [actualizar, setActualizar] = useState(false);
  const [fijados, setFijados] = useState([]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#4caf50',
      color: theme.palette.common.white,
      cursor: 'pointer',
      fontWeight: 'bold',
      padding: '10px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '0px'
    },
  }));

  useEffect(() => {
    const pedirdatos = async () => {
      try {
        const response = await fetch(BUSCAR_GASTOS_RENDICION, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id_propietario: propietarios.id_propietario, fecha: fechaSeleccionada }),
        });
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            setGastos(data.data);
            setGastosDescontar(data.data);
            setFijados(data.data.filter(gasto => gasto.fijar).map(gasto => gasto.id));
            setActualizaDatos(!actualizaDatos);
          } else {
            setGastos(null);
          }
        } else {
          console.error('Error al buscar los gastos:', response.status);
          setInmueble(null);
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
    };
    pedirdatos();
  }, [actualizar]);

  const handleAgregarGasto = () => {
    setOpenModalAgregarGastos(true);
  };

  const handleCerrarAgregarGasto = () => {
    setOpenModalAgregarGastos(false);
  };

  const handleDelete = (row) => {
    const deleteGasto = async () => {
      try {
        const response = await fetch(ELIMINAR_GASTO, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id_gasto: row.id }),
        });
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            setActualizar(!actualizar);
          }
        } else {
          console.error('Error al eliminar el gasto:', response.status);
          setInmueble(null);
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
    };
    deleteGasto();
  };
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      
      border: 0,
    },
  }));
  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const handleFijar = (row) => {
    const nuevoEstado = !fijados.includes(row.id);
    const updateGasto = async () => {
      try {
        const response = await fetch(ACTUALIZAR_GASTO, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id_gasto: row.id, fijar: nuevoEstado }),
        });
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            setFijados(prevState => {
              if (nuevoEstado) {
                return [...prevState, row.id];
              } else {
                return prevState.filter(id => id !== row.id);
              }
            });
          }
        } else {
          console.error('Error al actualizar el gasto:', response.status);
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
    };
    updateGasto();
  };

  return (
    <>
      <div className='flex-col w-full'>
        <div className='flex-col'>
          <div className='flex'>
            <div className='mx-auto mb-2'>
              <Button
                variant="outlined"
                sx={{ fontWeight: 'bold' }}
                color='success'
                startIcon={<AddIcon />}
                onClick={handleAgregarGasto}
              >
                AGREGAR Gasto
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full" style={{ maxHeight: '30vh', overflowY: 'auto' }}>
          <div style={{ overflowX: 'auto' }}>
            {gastos ?
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center" onClick={() => handleSort('detalle')}>
                        DETALLE
                      </StyledTableCell>
                      <StyledTableCell align="center" onClick={() => handleSort('importe')}>
                        MONTO
                      </StyledTableCell>
                      <StyledTableCell align="center" onClick={() => handleSort('fijar')}>
                        FIJAR
                      </StyledTableCell>
                      <StyledTableCell align="center" onClick={() => handleSort('eliminar')}>
                        ELIMINAR
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {gastos.map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell align="center">{row.detalle}</StyledTableCell>
                        <StyledTableCell align="center">{formatearMonedaARS(row.importe)}</StyledTableCell>
                        <StyledTableCell align="center">
                          <Checkbox
                            checked={fijados.includes(row.id)}
                            onChange={() => handleFijar(row)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <IconButton aria-label="delete" color="error" onClick={() => handleDelete(row)}>
                            <DeleteOutlinedIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              : null}
          </div>
        </div>
      </div>
      {openModalAgregarGastos && (
        <AgregarGastosModal
          isOpen={openModalAgregarGastos}
          onClose={handleCerrarAgregarGasto}
          id_propietario={propietarios.id_propietario}
          fecha={fechaSeleccionada}
          actualizar={actualizar}
          setActualizar={setActualizar}
        />
      )}
    </>
  );
};

export default TablaGastos;


