import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { TODOSINMUEBLES, CARGAR_INMUEBLE, ELIMINAR_INMUEBLE_INMUEBLES } from '../../utils/apiUrls';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import AddCardIcon from '@mui/icons-material/AddCard';
import ArticleIcon from '@mui/icons-material/Article';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SeleccionarInquilino from './SeleccionarInquilino';
import SeleccionarPropietario from './SeleccionarPropietario';
import SeleccionarServicios from './SeleccionarServicios';
import SeleccionarContratos from './SeleccionarContratos';
import AgregarPropiedad from './AgregarPropiedad';

const Index = () => {
  const [rows, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [propiedad, setPropiedad] = useState(null);
  const [openSeleccionarInquilino, setOpenSeleccionarInquilino] = useState(false);
  const [openSeleccionarPropietario, setOpenSeleccionarPropietario] = useState(false);
  const [openSeleccionarServicios, setOpenSeleccionarServicios] = useState(false);
  const [openSeleccionarContratos, setOpenSeleccionarContratos] = useState(false);
  const [actualiza, setActualiza] = useState(false);
  const [editPropiedad, setEditPropiedad] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [inmueble, setInmueble] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(TODOSINMUEBLES);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error en la solicitud');
        }
        const data2 = await response.json();
        setData(data2.data);
        setFilteredResults(data2.data);
      } catch (error) {
        console.error('Error de solicitud:', error.message);
      }
    };
    fetchData();
  }, [actualiza]);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    const filteredResults = rows.filter((row) => {
      return row.direccion.toLowerCase().includes(searchValue);
    });
    setSearchValue(event.target.value);
    setFilteredResults(filteredResults);
  };

  const handleEliminar = async (row) => {
    try {
      const response = await fetch(`${ELIMINAR_INMUEBLE_INMUEBLES}/${row.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error("Error eliminando inmueble");
      }
      setActualiza(!actualiza);
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error("Error eliminando inmueble", error);
    }
  };

  const handleOpenDeleteDialog = (row) => {
    setSelectedRow(row);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedRow(null);
  };

  const handleGuardar = async () => {
    try {
      const response = await fetch(CARGAR_INMUEBLE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ inmuebles: [selectedRow.id], id_edificio: selectedRow.id_edificio })
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      alert("Inmuebles guardados exitosamente");
      setOpenModal(false);
      setActualiza(!actualiza);
    } catch (error) {
      console.error("Error saving inmuebles", error);
    }
  };

  const handleSeleccionarInquilino = (row) => {
    setPropiedad(row);
    setOpenSeleccionarInquilino(true);
  };

  const handleSeleccionarPropietario = (row) => {
    setPropiedad(row);
    setOpenSeleccionarPropietario(true);
  };

  const handleSeleccionarServicios = (row) => {
    setPropiedad(row);
    setOpenSeleccionarServicios(true);
  };

  const handleSeleccionarContrato = (row) => {
    setPropiedad(row);
    setOpenSeleccionarContratos(true);
  };

  const handleEditarPropiedad = (row) => {
    setPropiedad(row);
    setEditPropiedad(true);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.common.white,
      cursor: 'pointer',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#f0f3ff',
      color: 'white',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: '#e8d8c7',
        color: '#a1ceff',
      },
    },
    '&:last-child td, &:last-child th': {
      border: 0,
      color: 'black',
    },
  }));

  return (
    <div className='flex-col w-full'>
      <div className='my-2'>
        <TextField
          label="Buscar"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={handleSearchChange}
          className="m-10 w-full"
        />
      </div>
      <div className="w-full sm:text-sm" style={{ maxHeight: '60vh', overflowY: 'auto' }}>
        <div style={{ overflowX: 'auto' }}>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ backgroundColor: '#0a3e9e', fontSize:'sm:text-sm' }}>
                  <StyledTableCell sx={{ fontWeight: 'bold', backgroundColor: '#0a3e9e' }}>
                    DIRECCION
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#0a3e9e' }}>
                    INQUILINO
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#0a3e9e' }}>
                    PROPIETARIO
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#0a3e9e' }}>
                    CONTRATO
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#0a3e9e' }}>
                    GASTOS
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#0a3e9e' }}>
                    EDITAR
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: '#0a3e9e' }}>
                    ELIMINAR
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredResults.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row.direccion + ' ' + row.altura + ' - ' + row.piso + ' - ' + row.unidad}  
                      {row.dias_diferencia < 65 ? <NotificationImportantIcon color='error' /> : null}
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <IconButton aria-label="inquilino" onClick={() => handleSeleccionarInquilino(row)}>
                        <GroupsIcon color={row.id_inquilino ? 'success': 'error'} />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <IconButton aria-label="propietario" onClick={() => handleSeleccionarPropietario(row)}>
                        <PersonIcon color={row.id_propietario ? 'success': 'error'} />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <IconButton aria-label="contrato" onClick={() => handleSeleccionarContrato(row)}>
                        <ArticleIcon color={row.id_contrato ? 'success': 'error'} />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <IconButton aria-label="servicios" onClick={() => handleSeleccionarServicios(row)}>
                        <AddCardIcon color={row.serviciosactivos ? 'success': 'error'} />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <IconButton aria-label="editar" onClick={() => handleEditarPropiedad(row)}>
                        <EditOutlinedIcon color='success' />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <IconButton aria-label="eliminar" onClick={() => handleOpenDeleteDialog(row)}>
                        <DeleteOutlineIcon color='error' />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {openSeleccionarInquilino && (
            <SeleccionarInquilino 
              open={openSeleccionarInquilino} 
              setOpenModal={setOpenSeleccionarInquilino} 
              inmueble={propiedad} 
              actualiza={actualiza} 
              setActualiza={setActualiza} 
            />
          )}
          {openSeleccionarPropietario && (
            <SeleccionarPropietario 
              open={openSeleccionarPropietario} 
              setOpenModal={setOpenSeleccionarPropietario} 
              inmueble={propiedad} 
              actualiza={actualiza} 
              setActualiza={setActualiza} 
            />
          )}
          {openSeleccionarServicios && (
            <SeleccionarServicios 
              open={openSeleccionarServicios} 
              setOpenModal={setOpenSeleccionarServicios} 
              inmueble={propiedad} 
              actualiza={actualiza} 
              setActualiza={setActualiza} 
            />
          )}
          {openSeleccionarContratos && (
            <SeleccionarContratos 
              open={openSeleccionarContratos} 
              setOpenModal={setOpenSeleccionarContratos} 
              inmueble={propiedad} 
              idPropiedad={propiedad.id} 
              actualiza={actualiza} 
              setActualiza={setActualiza} 
            />
          )}
          {editPropiedad && (
            <AgregarPropiedad 
              open={editPropiedad} 
              setOpenModal={setEditPropiedad} 
              setInmueble={setInmueble} 
              inmueble={propiedad} 
              idPropiedad={propiedad.id} 
              actualiza={actualiza} 
              setActualiza={setActualiza} 
            />
          )}
        </div>
      </div>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>{"Confirmar Eliminación"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar este inmueble?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}  color="secondary">
            Cancelar
          </Button>
          <Button onClick={() => handleEliminar(selectedRow)}  color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Index;
