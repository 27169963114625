import React, { useState, useEffect } from 'react';
import { Button, Modal, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import {ADD_INQUILINO, UPDATE_INQUILINO, BUSCARINQUILINO} from '../../utils/apiUrls'
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs'; // Importa dayjs
import AddIcon from '@mui/icons-material/Add';

const NuevoInquilino = ({ open, setOpenModal, inmueble, openOld, setOpenOld, actualiza, setActualiza, cambiarinquilino, inquilino,setInquilino, modificar}) => {
    const ariaLabel = { 'aria-label': 'description' };
    console.log(inmueble)
    const [inquilinos, setInquilinos] = useState(null)
    const [formulario, setFormulario] = useState({
        direccion: null,
        fecha_nacimiento: null,
        nombre: null,
        apellido:null,
        dni:null,
        telefono:null,
        email:null,
        genero: null,
        id: null,
        id_propiedad: inmueble.id
      });

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(BUSCARINQUILINO + '/' + inmueble.id);
    
            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.message || 'Error en la solicitud');
            }
            const data = await response.json();
            setInquilinos(data.data[0]);
             console.log(data.data[0])
             setFormulario({ ...formulario, 
                ['fecha_nacimiento']: data.data[0].fecha_nacimiento,
                ['direccion']: data.data[0].direccion,
                ['nombre']: data.data[0].nombre,
                ['apellido']: data.data[0].apellido,
                ['dni']: data.data[0].dni,
                ['telefono']: data.data[0].telefono,
                ['email']: data.data[0].email,
                ['genero']: data.data[0].genero,
                ['id']: data.data[0].id_inquilino
            })
                
           console.log(data.data[0])
          } catch (error) {
            console.error('Error de solicitud:', error.message);
          }
        };
        if (cambiarinquilino) {
          fetchData();
        }
      }, []);

   
     
  const handleSave = (event) => {
     const { name, value } = event.target;
     console.log(event)
    setFormulario({ ...formulario, [name]: value });
  };

  const handleSaveGenero = (event) => {
    const { name, value } = event.target;
    console.log(name, value)
   setFormulario({ ...formulario, ['genero']: value });
   console.log(formulario.genero)
 };
  const handleClose = () => {
    setOpenModal(false);
    setOpenOld(!openOld)

  };
  
  const handleCargarInmuebleExitoso = () => {
    // Muestra una alerta de SweetAlert2
    console.log('handleCargarInmuebleExitoso')
    setActualiza(!actualiza)
    handleClose()

    {inquilinos ? 
    
        Swal.fire({
            title: 'Actualizacion exitosa',
            text: 'El inquilino se ha ACTUALIZADO correctamente.',
            icon: 'success',
          })
        : 
        Swal.fire({
            title: 'Carga exitosa',
            text: 'El inquilino se ha CREADO correctamente.',
            icon: 'success',
          });
    }
   
  }

  const handleGuardar = () => {
      formulario.fecha_nacimiento = dayjs(formulario.fecha_nacimiento).format('YYYY-MM-DD')
      // Realiza una solicitud POST a tu API
    fetch(cambiarinquilino ? UPDATE_INQUILINO : ADD_INQUILINO , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formulario),
    })
      .then((response) => {
        if (response.ok) {
            handleCargarInmuebleExitoso();
        } else {

          // La solicitud falló, maneja el error, por ejemplo, mostrando un mensaje de error
          setOpenModal(!open)
          handleClose();
          Swal.fire({
            title: 'Ups...',
            text: 'Tuvimos un error al procesar el cambio. Aguarde unos segundos y vuelva a intentar...',
            icon: 'error',
          });
        }
      })
      .catch((error) => {
        // Maneja errores de red u otros errores
      });
  };

  
  const handleFechaNacimiento = (date) => {
    console.log(date)
    let fecha = dayjs(date).format('YYYY-MM-DD'); // Formatea la fecha

    console.log(fecha)
    setFormulario({ ...formulario, ['fecha_nacimiento']: fecha });
  };
  const handleAgregarInquilino = () => {
    setOpenModal(false)
    setInquilino(false)
  }

 
   return (
      
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "900px",
            height: "auto",
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            border: 2,
            borderColor:'#ede6db'
          }}
          style={{ maxHeight: '97%', overflow: 'auto' }}
        > 

            <div className='w-full text-xl font-bold text-center py-2 text-white bg-blue-500 mb-2'>
                INQUILINO
            </div>
            <Divider  color='secondary'/>
            <Divider  color='secondary'/>
            <Divider  color='secondary'/>
            <div className='w-full text-xl font-bold text-center py-2 text-black '>
                {inmueble.direccion + ' ' +  inmueble.altura + ' - Piso: ' + inmueble.piso + ' Unidad: ' + inmueble.unidad }
            </div>
            <Divider  color='secondary'/>
            <Divider  color='secondary'/>
            <Divider  color='secondary'/>

            <div className='w-full '>
                <div className='mx-auto w-fit mt-4'>
                    <Divider/>
                    {cambiarinquilino ? 
                      <div className='mt-4 text-center'>
                        <span className='text-red-800 mr-1'>Si el inquilino es INCORRECTO, desde aca vas a poder </span>
                          
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            // Aquí puedes agregar la lógica para manejar el evento onClick
                            handleAgregarInquilino()
                          }}
                        >
                          CAMBIAR DE INQUILINO
                        </Button>
                      </div>
                    : 
                      null
                    }
                    <div className='mx-auto w-fit mt-4'>  </div>
                    <Divider/>
                    <div className='pt-4  flex '>
                        <div className=' flex-col'>
                            <div className='pb-2 font-semibold'>Nombre</div>
                            <div className=' w-52'>
                                <Input placeholder="Fernando" value={formulario.nombre ? formulario.nombre : null} name='nombre' onChange={handleSave} inputProps={ariaLabel} fullWidth />
                            </div>
                        </div>
                        <div className='mx-8'>
                            <div className='pb-2  font-semibold'>Apellido</div>
                            <div className='w-52'>
                                <Input placeholder="Chiappero" value={formulario.apellido ? formulario.apellido : null} name='apellido' onChange={handleSave} inputProps={ariaLabel} fullWidth />
                            </div>
                        </div>
                        <div className=' col-span-3'>
                            <div className='pb-2 font-semibold'>Dni</div>
                            <div className='w-32'>
                                <Input placeholder="36333333" value={formulario.dni ? formulario.dni : null} name='dni' onChange={handleSave} inputProps={ariaLabel} fullWidth />
                            </div>
                        </div>
                        <div className='flex-col ml-8'>
                            <div className='font-semibold '>Fecha Nacimiento</div>
                            <LocalizationProvider size="small"  dateAdapter={AdapterDayjs}>
                            <DateField 
                                format="DD/MM/YYYY" 
                                size="small"
                                sx={{width:'160px'}}
                                value={formulario.fecha_nacimiento ? dayjs(formulario.fecha_nacimiento) : null}
                                className="no-padding-textfield"
                                onChange={(date) => handleFechaNacimiento(date)}
                                renderInput={(params) => (
                                <TextField {...params} variant="standard" size="small" />
                                )}
                            />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className='flex pt-4'>
                        <div className=' mr-8'>
                            <div className='pb-2 font-semibold'>Direccion</div>
                            <div className='w-32'>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Input placeholder="Diego Diaz 663" value={formulario.direccion ? formulario.direccion : null} name='direccion' onChange={handleSave} inputProps={ariaLabel}/>
                                </div>
                            </div>
                        </div>
                        <div className=' mr-8'>
                            <div className='pb-2 font-semibold'>Telefono</div>
                            <div className='w-32'>
                                <Input placeholder="3513303030" name='telefono' value={formulario.telefono ? formulario.telefono : null} onChange={handleSave} inputProps={ariaLabel} fullWidth />
                            </div>
                        </div>
                        <div className=' mr-8'>
                            <div className='pb-2 font-semibold'>Email</div>
                            <div className='w-52'>
                                <Input placeholder="info@gmail.com.ar" name='email' value={formulario.email ? formulario.email : null} onChange={handleSave} inputProps={ariaLabel} fullWidth />
                            </div>
                        </div>
                        <div className=''>
                            <div className=' font-semibold'>Genero</div>
                            <div className='w-32'>
                                <div className='w-32'>
                                    <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={formulario.genero ? formulario.genero : null}
                                    onChange={handleSaveGenero}
                                    name='genero'
                                    label="Age"
                                    size='small'
                                    fullWidth
                                    >
                                    <MenuItem value={0}>Masculino</MenuItem>
                                    <MenuItem value={1}>Femenino</MenuItem>
                                    </Select>
                                </div>                            
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'></div>
                    <Divider/>
                    <div className='mt-4'>
                        <Divider/>
                        <div className='mt-4 mb-2 text-center flex'>
                            <div className='mx-auto'>
                                <Stack spacing={2} direction="row">
                                    <Button  variant="contained" color='success' onClick={handleGuardar}> {inquilino ? 'Actualizar':'Guardar'} </Button>
                                    <Button variant="contained" color='error' onClick={handleClose}>Cancelar</Button>
                                </Stack>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
      </Modal>

    </>
  );
};

export default NuevoInquilino;