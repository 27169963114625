import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FiHome, FiDollarSign, FiClipboard, FiBriefcase, FiSettings } from 'react-icons/fi';
import { useStyles } from '../../utils/funciones';

function Servicios() {
  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  const servicios = [
    {
      icono: <FiSettings className="text-5xl" />,
      titulo: "Administración de Propiedades",
      descripcion: "Ofrecemos servicios de administración de propiedades para propietarios e inversores."
    },
    {
      icono: <FiHome className="text-5xl" />,
      titulo: "Alquileres",
      descripcion: "Te ayudamos a encontrar el lugar perfecto para vivir."
    },
    {
      icono: <FiDollarSign className="text-5xl" />,
      titulo: "Ventas",
      descripcion: "Encuentra el hogar de tus sueños entre nuestras propiedades en venta."
    },
    {
      icono: <FiClipboard className="text-5xl" />,
      titulo: "Tasaciones",
      descripcion: "Valuamos tu propiedad de manera profesional y confiable."
    },
    {
      icono: <FiBriefcase className="text-5xl" />,
      titulo: "Asesoramiento",
      descripcion: "Brindamos asesoramiento experto en el proceso de compra, venta y alquiler de propiedades."
    },
   
  ];

  return (
    <section className=" text-blue-800 py-6 text-center">
      <h2 className="text-4xl font-bold mb-8">¿Qué Hacemos?</h2>

      <Slider {...settings}>
        {servicios.map((servicio, index) => (

          <div key={index} className="flex flex-col items-center justify-center  py-4">
            <div className="bg-blue-700 mx-auto text-white rounded-full w-20 h-20 flex items-center justify-center transition duration-300 hover:shadow-xl">
              { servicio.icono }
            </div>
            <h3 className="text-xl font-bold mt-4 mb-2">{servicio.titulo}</h3>
            <p className="">{servicio.descripcion}</p>
          </div>
        ))}
      </Slider>
    </section>
  );
}

export default Servicios;
