import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { BUSCARPROPIETARIOS, ADD_SELECT_PROPIETARIO } from '../../utils/apiUrls';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Swal from 'sweetalert2';

const TablaPropietarios = ({inmueble, actualiza, setActualiza, closeModal}) => {
  console.log(inmueble)
  const [rows, setData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  console.log(inmueble)
  const handleAgregarInquilino = (row) => {
    const requestBody = {
      row: row,
      id_propiedad: inmueble.id,
    };

    fetch(ADD_SELECT_PROPIETARIO, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Ajusta el tipo de contenido según tu API
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error en la solicitud POST');
        }
        return response.json();
      })
      .then((data) => {
        // Maneja la respuesta exitosa aquí
        console.log('Solicitud POST exitosa:', data);
        setActualiza(!actualiza)
        closeModal()
        Swal.fire({
          title: 'Seleccion Exitosa',
          text: 'El inquilino se ha SELECCIONADO CORRECTAMENTE.',
          icon: 'success',
        });
      })
      .catch((error) => {
        // Maneja cualquier error en la solicitud
        console.error('Error en la solicitud POST:', error);
        closeModal()

        Swal.fire({
          title: 'Ups...',
          text: 'Tuvimos un error al procesar el cambio. Aguarde unos segundos y vuelva a intentar...',
          icon: 'error',
        });
      });
      }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.common.white,
      cursor: 'pointer',
      position: 'sticky',
      top: 0,
      zIndex: 1, // Para que el encabezado quede encima del contenido desplazable
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#f0f3ff',
      color: 'white',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: '#e8d8c7',
        color: '#a1ceff',
      },
    },
    '&:last-child td, &:last-child th': {
      border: 0,
      color: 'black',
    },
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BUSCARPROPIETARIOS);

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error en la solicitud');
        }
      
          const data2 = await response.json();
          setData(data2.data);
    
       console.log(response)
      } catch (error) {
        console.error('Error de solicitud:', error.message);
      }
    };

    fetchData();
  }, [actualiza]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };
  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    console.log(searchValue)
    if (searchValue === '') {
      // Si el valor de búsqueda está vacío, restaura la lista de resultados a la lista completa de datos
      setSearchResults([]);
    } else {
      const filteredResults = rows.filter((row) => {
        // Convierte a minúsculas los valores de nombre, apellido y DNI para hacer la búsqueda insensible a mayúsculas
        const nombre = row.nombre.toLowerCase();
        const apellido = row.apellido.toLowerCase();
        const dni = row.dni.toString().toLowerCase();
        
        // Verifica si el valor de búsqueda está presente en nombre, apellido o DNI
        return nombre.includes(searchValue) || apellido.includes(searchValue) || dni.includes(searchValue);
      });
      
      setSearchResults(filteredResults);
    }
  
    setSearchValue(event.target.value);
  };
  
  return (
    <div className=' flex-col w-full'>
      <div className='my-2'>
        <TextField
          label="Buscar"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={handleSearchChange}
          className=" m-10 w-full"
        />
      </div>
      <div className="w-full" style={{ height: '400px', overflowY: 'auto' }}>
        <div style={{ overflowX: 'auto' }}>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ backgroundColor: '#0a3e9e' }}>
                  <StyledTableCell align="center" onClick={() => handleSort('direccion')} sx={{ backgroundColor: '#0a3e9e' }}>
                    Nombre
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ backgroundColor: '#0a3e9e' }}>
                    Apellido
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ backgroundColor: '#0a3e9e' }}>
                    Dni
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ backgroundColor: '#0a3e9e' }}>
                    Seleccionar
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchValue === '' ? (
                  rows.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell align="center" component="th" scope="row">
                        {row.nombre } 
                      </StyledTableCell>
                      <StyledTableCell align='center' >
                        {row.apellido } 
                      </StyledTableCell>

                      <StyledTableCell align='center'>
                        {row.dni } 
                      </StyledTableCell>
                      <StyledTableCell align='center' >
                        <IconButton aria-label="propietario" onClick={() => handleAgregarInquilino(row)}>
                          <HowToRegIcon color='primary' />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  searchResults.map((row) => (
                    <StyledTableRow key={row.id}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {row.nombre } 
                    </StyledTableCell>
                    <StyledTableCell align='center' >
                      {row.apellido } 
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      {row.dni } 
                    </StyledTableCell>
                    <StyledTableCell align='center' >
                      <IconButton aria-label="propietario" onClick={() => handleAgregarInquilino(row)}>
                        <HowToRegIcon color='primary' />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};
export default TablaPropietarios;
