import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import { Modal, Button, Box } from "@mui/material";

const ModalFijarGasto = ({
  open,
  setOpenModal,
  inmueble,
  actualiza,
  setActualiza,
  gasto
}) => {
  const [mesesSeleccionados, setMesesSeleccionados] = useState([]);

  const handleGuardar = () => {
    // Aquí puedes hacer lo que necesites con los meses seleccionados
    console.log("Meses seleccionados:", mesesSeleccionados);
  };

  const toggleMes = mes => {
    if (mesesSeleccionados.includes(mes)) {
      setMesesSeleccionados(mesesSeleccionados.filter(m => m !== mes));
    } else {
      setMesesSeleccionados([...mesesSeleccionados, mes]);
    }
  };

  const selectAll = () => {
    setMesesSeleccionados([
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ]);
  };

  const deselectAll = () => {
    setMesesSeleccionados([]);
  };

  const handleClose = () => {
    setOpenModal(!open);
  };

  const mesClasses = mes => {
    return mesesSeleccionados.includes(mes)
      ? "p-2 m-2 bg-green-600 rounded w-32 cursor-pointer font-semibold"
      : "p-2 m-2 bg-gray-600 text-white rounded w-32 cursor-pointer font-semibold";
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "auto",
          maxHeight: "screeen",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 1
        }}
        style={{ maxHeight: "97%", overflow: "auto" }}
      >
        <div className="w-full mb-2">
          <h1 className="w-full text-xl font-bold text-center py-2 text-white bg-blue-500 ">
            FIJAR GASTO
          </h1>
          <div className="w-full text-xl font-bold text-center py-1 text-black ">
            {inmueble.direccion +
              " " +
              inmueble.altura +
              " - Piso: " +
              inmueble.piso +
              " Unidad: " +
              inmueble.unidad}
          </div>
          <Divider color="secondary" />
          <Divider color="secondary" />
          <Divider color="secondary" />
          <div className="w-full text-xl font-bold text-center py-1 text-black ">
            {gasto.servicio}
            <div className="flex justify-end w-full -mt-6 pr-32">
            <div className="">
              <Button variant="contained" color="success" onClick={selectAll}>
                AGREGAR Todos
              </Button>
            </div>
            <div className="ml-2">
              <Button variant="contained" color="error" onClick={deselectAll}>
                Eliminar Todos
              </Button>
            </div>
          </div>
          </div>


          <div className="w-full text-xl text-center py-1">
            Seleccione los meses donde quiere que se repita este gasto.
          </div>

          <div className="w-10/12 mx-auto text-center flex flex-wrap justify-center ">
            {[
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
            ].map((mes, index) =>
              <div
                key={index}
                className={mesClasses(mes)}
                onClick={() => toggleMes(mes)}
              >
                {mes}
              </div>
            )}
          </div>
        </div>
        <Divider color={"secondary"} />
        <Divider color={"secondary"} />
        <Divider color={"secondary"} />
        <div className="flex">
          <div className="mx-auto flex my-4">
            <div className="mr-4">
              <Button
                variant="contained"
                color="primary"
                onClick={handleGuardar}
              >
                Guardar
              </Button>
            </div>
            <div className="ml-4 ">
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancelar
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalFijarGasto;
