import React from "react";
import { Typography, Container } from "@mui/material";

const Title = ({ title }) => {
  return (
    <Container className="w-full text-start ">
      <Typography variant="body1" gutterBottom>
        <div className="flex w-full  mb-4 -ml-6">
          <p className=" text-blue-500 font-semibold px-1 text-2xl">
            {" "}BIENVENIDO
          </p>
          <p className="flex mt-1.5 "> a tu nuevo Sistema de Gestión.</p>
        </div>
      </Typography>
    </Container>
  );
};
export default Title;
