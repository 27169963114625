import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { PROPIETARIOS } from '../../utils/apiUrls';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { convertirFecha } from '../../utils/funciones';
import TextField from '@mui/material/TextField';
import BarChartIcon from '@mui/icons-material/BarChart';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs'; // Importa dayjs
import ModalRendicion from './ModalRendicion'
import { formatearMonedaARS } from '../../utils/funciones';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddIcon from '@mui/icons-material/Add';


const TablaRendiciones = ({propietarios, setTotalInmuebles, inmueble,setInmueble, setFechaSeleccionada, fechaSeleccionada, setActualizaDatos, actualizaDatos,totalRendicionLocal,setTotalRendicionLocal}) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchValue, setSearchValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModalRendicion, setOpenModalRendicion] = useState(false);
  const [propietario, setPropietario] = useState(false);
  const [fecha, setFecha] = useState(dayjs());
  const [actualizar, setActualizar] = useState(false)
  const [updatedInmueble, setUpdatedInmueble] = useState(inmueble)
  const [gastos, setGastos] = useState(null)
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#4caf50',
      color: theme.palette.common.white,
      cursor: 'pointer',
      fontWeight: 'bold',
      padding: '10px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding:'0px'
    },
  }));
  useEffect(() => {
    let total = 0;
    // Sumar los montos de los gastos
    if (updatedInmueble && Array.isArray(updatedInmueble)) {
        updatedInmueble.forEach(itemInmueble => {
            total += parseFloat(itemInmueble.alquiler_precio);
        });
        setTotalRendicionLocal(total)
    }
}, [updatedInmueble]);

  useEffect(() => {
    setUpdatedInmueble(updatedInmueble);
  }, [updatedInmueble]);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      
      border: 0,
    },
  }));

  const handleFecha = (date) => {
    // Convertir el objeto Date a dayjs antes de actualizar el estado
    setFecha(dayjs(date));
    setFechaSeleccionada(dayjs(date))
    setActualizaDatos(!actualizaDatos)
  };
 

  const handleSort = (column) => {
    if (sortBy === column) {
      // Si hacemos clic en la misma columna, cambiamos el orden
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Si hacemos clic en una columna diferente, establecemos esa columna como la columna de ordenación
      setSortBy(column);
      setSortOrder('asc');
    }
  };



  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
  // Ordenamos los datos si tenemos una columna de ordenación seleccionada
  const handleDelete = (id) => {
    // Filtrar el arreglo para excluir el elemento con el ID proporcionado
    const updatedInmueble = inmueble.filter(item => item.id !== id);
    // Actualizar el estado con el nuevo arreglo
    setInmueble(updatedInmueble);
    setTotalInmuebles(updatedInmueble)
    setUpdatedInmueble(updatedInmueble)
    // Aquí puedes agregar la lógica para borrar la fila con el ID proporcionado de tu base de datos o cualquier otro lugar donde estén almacenados los datos
  };
  
  const handleOpenModalRendicion = (row) =>{
    setPropietario(row)
    setOpenModalRendicion(!openModalRendicion)
  }

  return (
   <>
   <div className=' flex-col w-full '>
   <div className='flex-col '>
     <div className=' items-end w-full flex   pr-4 mb-2'>
        <div className='pb-2 font-semibold'>
          Honorarios:  {propietarios.honorarios}
        </div>
       
         <div className='mr-2  mt-4 mx-auto  mb-2 font-bold'>MES Y AÑO: </div>
         <LocalizationProvider size="small"  dateAdapter={AdapterDayjs}>
           <DateField 
               format="MM/YYYY" 
               size="small"
               sx={{ width: '160px' }}
               value={fechaSeleccionada} // Usa fecha convertida a dayjs
               onChange={(date) => handleFecha(date)}
               renderInput={(params) => (
               <TextField {...params} variant="standard" size="small" />
               )}
           />
         </LocalizationProvider>
         
       </div>

  
     
    </div>
    
     <div className="w-full" style={{ maxHeight: '30vh', overflowY: 'auto' }}>
       <div style={{ overflowX: 'auto' }}>
         
       <TableContainer component={Paper}>
         <Table  stickyHeader aria-label="sticky table">
           <TableHead  >
               <TableRow >
                 <StyledTableCell  align="center" onClick={() => handleSort('nombre')}>
                   DIRECCION
                 </StyledTableCell>
                 <StyledTableCell align="center" onClick={() => handleSort('apellido')}>
                   ALTURA
                 </StyledTableCell>
                 <StyledTableCell align="center" onClick={() => handleSort('dni')}>
                   PISO
                 </StyledTableCell>
                 <StyledTableCell align="center" onClick={() => handleSort('direccion')}>
                   UNIDAD
                 </StyledTableCell>
                 <StyledTableCell align="center" onClick={() => handleSort('alquiler')}>
                   ALQUILER
                 </StyledTableCell>
                 <StyledTableCell align="center" onClick={() => handleSort('abono')}>
                   ABONO
                 </StyledTableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               {updatedInmueble ? updatedInmueble.map((row) => (
                 <StyledTableRow key={row.id}>
                   <StyledTableCell align="center">{row.direccion}</StyledTableCell>
                   <StyledTableCell align="center">{row.altura}</StyledTableCell>
                   <StyledTableCell align="center">{row.piso}</StyledTableCell>
                   <StyledTableCell align="center">{row.unidad}</StyledTableCell>
                   <StyledTableCell align="center">{formatearMonedaARS(row.alquiler_precio)}</StyledTableCell>
                   {row.abono == '0' ?
                   <StyledTableCell onClick={() => handleDelete(row.id)}  align="center"><DeleteOutlineIcon color='error' className=' hover:cursor-pointer'/></StyledTableCell>
                   :
                   <StyledTableCell align="center"><CheckCircleOutlineIcon color='success'/></StyledTableCell>
                  }
                 </StyledTableRow>
               )): 
               inmueble && Array.isArray(inmueble)  ? inmueble.map((row) => (
                <StyledTableRow key={row.dni}>
                  <StyledTableCell align="center">{row.direccion}</StyledTableCell>
                  <StyledTableCell align="center">{row.altura}</StyledTableCell>
                  <StyledTableCell align="center">{row.piso}</StyledTableCell>
                  <StyledTableCell align="center">{row.unidad}</StyledTableCell>
                  <StyledTableCell align="center">{formatearMonedaARS(parseFloat(row.alquiler_precio))}</StyledTableCell>
                  {row.abono == '0' ?
                  <StyledTableCell onClick={() => handleDelete(row.id)}  align="center"><DeleteOutlineIcon color='error' className=' hover:cursor-pointer'/></StyledTableCell>
                  :
                  <StyledTableCell align="center"><CheckCircleOutlineIcon color='success'/></StyledTableCell>
                 }
                </StyledTableRow>
              )): null
               }
             </TableBody>
           </Table>
         </TableContainer>
       </div>
     </div>
   </div>
    
   </>
  );
};
export default TablaRendiciones;
