import React from 'react';
import { useState } from 'react';
import Navar from '../utils/Navar'
import TablePropietarios from '../components/rendiciones/Tabla'
import Title from '../components/rendiciones/Title';
import { useStyles } from '../utils/funciones';

const Dashboard = ({setIsAuthenticated}) => {
  const classes = useStyles();

  return (
    <>
    <div className="bg-gray-100  h-screen flex">
    <Navar setIsAuthenticated={setIsAuthenticated} />
    <div className="w-full text-center flex-col mx-auto mt-20 -ml-20  ">
    <div className={classes.card}>
            <div className='p-4 '>
            <Title/>
            </div>
            <div className='p-4'>
            <TablePropietarios />
            </div>          
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
