import React, { useState } from 'react';
import { FiHome, FiBriefcase, FiPhone, FiLogIn } from 'react-icons/fi';
import { VscChromeClose } from "react-icons/vsc";

import logo from '../../assets/images/logo.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-blue-900 shadow-lg  w-full z-10">
      <div className=" mx-auto w-full ">
        <div className="flex justify-between h-16">
          {/* Logo y nombre */}
          <div className="flex items-center">
            <img className="h-8 w-auto mr-2" src={logo} alt="Logo" />
            <span className="text-white text-xl font-bold">Chiappero Inmobiliaria</span>
          </div>

          {/* Botón para abrir el menú en pantallas pequeñas */}
          <div className={`flex md:hidden items-center ${isOpen ? 'hidden' : 'block'}`}>
            <button className="text-white px-3 py-2 rounded-md text-sm font-medium focus:outline-none" onClick={toggleMenu}>
              <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>

          {/* Links */}
          <div className={`md:flex xl:flex lg:flex items-center space-x-4   sm:items-stretch sm:mt-4 ${isOpen ? 'sm:bg-blue-900 sm:h-fit sm:py-8 sm:fixed sm:right-2 sm:flex-col top:20' : 'hidden'}`}>
            <div className={`${isOpen ? 'pt-0 pl-4 ' : ''}`}>
              <a href="#" className="text-white flex items-center px-3 py-2 rounded-md text-sm font-medium">
                <FiHome className="mr-2" />
                Inicio
              </a>
            </div>
            <div className={`${isOpen ? 'py-2' : ''}`}>
              <a href="#" className="text-white flex items-center px-3 py-2 rounded-md text-sm font-medium">
                <FiBriefcase className="mr-2" />
                Propiedades
              </a>
            </div>
            <div className={`${isOpen ? 'py-2' : ''}`}>
              <a href="#" className="text-white flex items-center px-3 py-2 rounded-md text-sm font-medium">
                <FiBriefcase className="mr-2" />
                Servicios
              </a>
            </div>
            <div className={`${isOpen ? 'py-2' : ''}`}>
              <a href="#" className="text-white flex items-center px-3 py-2 rounded-md text-sm font-medium">
                <FiPhone className="mr-2" />
                Contacto
              </a>
            </div>
            <div className={`${isOpen ? 'py-2' : ''}`}>
              <a href="/login" className="text-white flex items-center px-3 py-2 rounded-md text-sm font-medium">
                <FiLogIn className="mr-2" />
                LogIn
              </a>
            </div>
            <div className={`${isOpen ? 'py-2 ' : ''}`}>
              <a href="#" className="md:hidden  text-white flex items-center px-3 py-2 rounded-md text-sm font-medium" onClick={toggleMenu}>
                <VscChromeClose  className="mr-2" />
                Cerrar
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
