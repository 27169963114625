import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from './../../assets/images/logo.png';
import facebook from './../../assets/images/facebook.png';
import instagram from './../../assets/images/instagram.png';
import firma from './../../assets/images/firma.png';
import { formatearFechaISO8601, formatearMonedaARS } from '../../utils/funciones';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFF',
    padding: 40,
  },
  section: {
    flexGrow: 1,
    textAlign: 'center',
  },
  logoContainer: {
    marginBottom: 20,
  },
  logo: {
    width: 75,
    height: 75,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  firma: {
    width: 150, // Ancho de la firma
    height: 150, // Alto de la firma
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom:120
  },
  iconos: {
    height:'8px',
    width:'8px',
    position: 'relative',
    top: 2, // Ajusta la posición vertical del icono con el texto
    left: 5, // Ajusta la posición horizontal del icono con el texto
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#333',
    borderBottom: '2pt solid #333',
    paddingBottom: 5,
    marginTop:10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 20,
    color: '#666',
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
    color: '#555',
  },
  footer: {
    position: 'absolute',
    bottom: 97,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 10,
    color: '#999',
    marginTop: 20,
  },
  socialLinks: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  socialLinkstext: {
    fontSize: 8,
    marginTop: 5,
    color: '#666',
  },
  separator: {
    borderBottom: '1pt solid #999',
    marginBottom: 10,
  },
});
const fecha_pago = new Date();
let hora = fecha_pago.getHours();
if (hora < 10) {
  hora = '0' + hora
}
let minutos = fecha_pago.getMinutes();
if (minutos < 10) {
  minutos = '0' + minutos
}
const hora_pago = hora + ":" + minutos;
let mes = fecha_pago.getMonth() + 1; // Obtener el mes (0-11)
if (mes < 10) {
  mes = '0' + mes
}
const anio = fecha_pago.getFullYear() ; // Obtener el mes (0-11)
let dia = fecha_pago.getDate(); // Obtener el día del mes (1-31)
if (dia < 10) {
  dia = '0' + dia
}
const fecha_format = dia + "-"+ mes+ "-"+anio

function formatearMes(mes) {
  return mes < 10 ? '0' + mes : mes;
}

const MyDocument = ({inmueble, gastos, totalGastos, montoIntereses,abonaInteres, numOperacion, mesAbono}) => (
   <>
   {console.log(abonaInteres)}
   {console.log(montoIntereses)}
    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.logoContainer}>
          <Image src={logo} style={styles.logo} />
        </View>
        <Text style={styles.subtitle}>Inmobiliaria Chiappero</Text>
        <Text style={styles.text}>
            <Text style={styles.text}> Fecha de Impresion: {fecha_format ? fecha_format : null} - {hora_pago ? hora_pago : null} Hs.</Text>
        </Text>
        <Text style={styles.title}>DETALLE GASTOS ALQUILER</Text>
        <Text style={styles.text}>Inquilino/a: {inmueble.nombre + " " + inmueble.apellido + " Dni: " + inmueble.dni}</Text>
        <Text style={styles.text}>Inmueble: {inmueble.direccion + " " + inmueble.altura + " " + inmueble.piso + " " + inmueble.unidad}</Text>
        <Text style={styles.text}>
        Alquiler correspondiente al mes de {
            mesAbono ? mesAbono instanceof Date 
            ? formatearMes(mesAbono.getMonth() + 1)  +"-"+ mesAbono.getFullYear()  
            : formatearMes(new Date(mesAbono).getMonth() + 1)  +"-"+ new Date(mesAbono).getFullYear()  
              ? formatearMes(new Date(mesAbono).getMonth() + 1)  +"-"+ new Date(mesAbono).getFullYear()  
              : 'Fecha no válida'
          : null}
         
        </Text>        
        <Text style={{
          paddingVertical: 2,
          paddingRight: 10,
          fontSize: 12,
          marginBottom: 5,
          color: '#555',
        }}>
          Monto del Alquiler: {formatearMonedaARS(parseFloat(inmueble.alquiler_precio))}
        </Text>

        {gastos ? gastos.map((facturaItem, index) => (
          facturaItem.item == 'Alquiler' ?
            <Text key={index} style={{
              paddingVertical: 2,
              paddingRight: 10,
              fontSize: 12,
              marginBottom: 5,
              color: '#555',
            }}>
              Monto del Alquiler: {formatearMonedaARS(parseFloat(facturaItem.monto))}
            </Text>
          : null
        )
        ): 
        null
        } 
    

         {/* Recorre las facturas y crea textos */}
       {/* Verifica si factura es un array antes de mapearlo */}
       {gastos.map((facturaItem, index) => (
          facturaItem.item !== 'Alquiler'  ?
          <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: '170px', width: '151px' }}>
          <Text style={{
            paddingVertical: 2,
            paddingRight: 10,
            fontSize: 12,
            marginBottom: 5,
            color: '#555',
          }}>
            {facturaItem.item ? facturaItem.item : facturaItem.servicio}:
          </Text>
          <Text style={{
            padding: 2,
            fontSize: 12,
            marginBottom: 5,
            textAlign: 'end',
            color: '#555',
          }}>{formatearMonedaARS(parseFloat(facturaItem.monto))}</Text>
        </View>
        
        : null
        ))}
           {abonaInteres ?
              <View key={99} style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: '170px', width: '151px' }}>
              <Text style={{
                paddingVertical: 2,
                paddingRight: 10,
                fontSize: 12,
                marginBottom: 5,
                color: '#555',
              }}>
                Intereses:
              </Text>
              <Text style={{
                padding: 2,
                fontSize: 12,
                marginBottom: 5,
                textAlign: 'end',
                color: '#555',
              }}>{formatearMonedaARS(parseFloat(montoIntereses))}</Text>
            </View>
          : null
        }
        <Text style={{            
            padding: 2,  
            fontSize: 16,
            fontWeight:'black',
            marginBottom: 5,
            }}>
              TOTAL A ABONAR:  {totalGastos} 
        </Text>
        {/* Agrega más detalles del recibo si es necesario */}


        

        <View style={styles.socialLinks}>
          <View style={styles.separator} />
          <Text style={styles.socialLinkstext}>Para más información, contáctanos:</Text>
          <Text style={styles.socialLinkstext}>Horarios de Atención: Lunes a Viernes de 9:00 a 18:00 hs</Text>
          <Text style={styles.socialLinkstext}>Email: Inmobiliariachiappero@gmail.com</Text>
          <Text style={styles.socialLinkstext}>Teléfono: (03532)  67-8233</Text>
          <Text style={styles.socialLinkstext}>
            <Image src={facebook} style={styles.iconos} />
            - Síguenos en Facebook: @Inmobiliariachiappero
          </Text>
          <Text style={styles.socialLinkstext}>
            <Image src={instagram} style={styles.iconos} />
            - Síguenos en Instagram: @Inmobiliariachiappero
          </Text>
        </View>
      </View>
    </Page>
  </Document>  
  
  </>
);

export default MyDocument;
