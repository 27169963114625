import React, { useState, useEffect } from "react";
import { CARGAR_EXPENSAS, ELIMINAR_EXPENSAS } from "../../utils/apiUrls";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DescriptionIcon from "@mui/icons-material/Description";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PaymentIcon from "@mui/icons-material/Payment";
import NotesIcon from "@mui/icons-material/Notes";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CategoryIcon from "@mui/icons-material/Category";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";

const initialState = {
  amount: "",
  description: "",
  isInvoice: false,
  invoiceNumber: "",
  isCreditNote: false,
  isReceipt: false,
  receiptNumber: "",
  date: "",
  paymentMethod: "Efectivo",
  notes: "",
  cuotas: "1",
  category: "",
};

const categories = [
  { nombre: 'Bancarios', color: '#FF5733' },
  { nombre: 'Impuestos y Servicios', color: '#33C3FF' },
  { nombre: 'Limpieza', color: '#33FF57' },
  { nombre: 'Mantenimiento', color: '#FFC300' },
  { nombre: 'Seguridad', color: '#C70039' },
  { nombre: 'Sueldos y Honorarios', color: '#900C3F' }
];

const ModalExpensas = ({
  openExpensas,
  setOpenExpensas,
  fetchExpenses,
  expenses,
  id,
  setId_Edificio,
  fecha,
  setFecha,
}) => {
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    if (!openExpensas) {
      setFormState(initialState);
    }
  }, [openExpensas]);

  if (!openExpensas) return null;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormState((prevState) => ({
        ...prevState,
        isInvoice: false,
        isCreditNote: false,
        isReceipt: false,
        [name]: checked,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const expense = {
      ...formState,
      id_edificio: id,
      fechaSeleccionada: fecha
    };

    await fetch(CARGAR_EXPENSAS, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(expense),
    });

    fetchExpenses();
    setFormState(initialState);
  };

  const handleDelete = async (expenseId) => {
    await fetch(ELIMINAR_EXPENSAS, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: expenseId }),
    });

    fetchExpenses();
  };

  const handleClose = () => {
    setId_Edificio(0);
    setOpenExpensas(!openExpensas);
  };

  return (
    <div className="inset-0 min-w-full bg-opacity-10 flex-col justify-center items-center z-50">
      <div className="min-w-full -mt-10 mb-10 end-0">
        <div className="flex text-end items-end mr-72 mt-2 -mb-10">
          <div className="mr-2 mx-auto mb-2 font-bold">MES Y AÑO: </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              format="MM/YYYY"
              value={dayjs(fecha)}
              onChange={(date) => setFecha(dayjs(date))}
              renderInput={(params) => <TextField {...params} variant="standard" />}
              size="small"
              style={{maxWidth:'fit', width:'100px'}}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="flex justify-center items-center ">
        <div className="w-full max-w-lg mx-4 rounded-lg p-6 overflow-y-auto  shadow-lg -mt-10" >
          <h2 className="text-2xl font-semibold mb-6 text-gray-800 flex items-center">
            <DescriptionIcon className="mr-2" />
            Cargar Expensas
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="flex">
              <div className="mb-4 w-2/3">
                <label className=" text-gray-700 font-medium flex items-center mb-1">
                  <DescriptionIcon className="mr-2" />
                  Descripción:
                </label>
                <input
                  name="description"
                  value={formState.description}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="mb-4 w-1/3 ml-2">
                <label className="mb-1 text-gray-700 font-medium flex items-center">
                  <PaymentIcon className="mr-2" />
                  Importe:
                </label>
                <input
                  type="number"
                  name="amount"
                  value={formState.amount}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
            <div className="flex mb-4">
            <label className=" text-gray-700 font-medium flex items-center mr-4">
                <ReceiptIcon className="mr-2" />
                <input
                  type="checkbox"
                  name="isReceipt"
                  checked={formState.isReceipt}
                  onChange={handleChange}
                  className="mr-2"
                />
                Recibo
              </label>
              <label className=" text-gray-700 font-medium flex items-center">
                <FactCheckIcon className="mr-2" />
                <input
                  type="checkbox"
                  name="isInvoice"
                  checked={formState.isInvoice}
                  onChange={handleChange}
                  className="mr-2"
                />
                Factura
              </label>
              <label className=" text-gray-700 font-medium flex items-center ml-4">
                <CreditScoreIcon className="mr-2" />
                <input
                  type="checkbox"
                  name="isCreditNote"
                  checked={formState.isCreditNote}
                  onChange={handleChange}
                  className="mr-2"
                />
                Nota de Crédito
              </label>
     
            </div>
            {(formState.isInvoice || formState.isReceipt || formState.isCreditNote) && (
              <div className="mb-4">
                <label className=" text-gray-700 font-medium flex items-center">
                  Ingrese el Número de {formState.isInvoice ? "Factura" : formState.isReceipt ? "Recibo" : "Nota de Crédito"}:
                </label>
                <input
                  type="text"
                  name={formState.isInvoice ? "invoiceNumber" : "receiptNumber"}
                  value={formState.isInvoice ? formState.invoiceNumber : formState.receiptNumber}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            )}
            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label className=" text-gray-700 font-medium flex items-center mb-1">
                  <DateRangeIcon className="mr-2" />
                  Fecha:
                </label>
                <input
                  type="date"
                  name="date"
                  value={formState.date}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="w-1/2 pl-2">
                <label className=" text-gray-700 font-medium flex items-center mb-1">
                  <PaymentIcon className="mr-2" />
                  Método de Pago:
                </label>
                <select
                  name="paymentMethod"
                  value={formState.paymentMethod}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                >
                  <option value="Efectivo">Efectivo</option>
                  <option value="Tarjeta de Débito">Tarjeta de Débito</option>
                  <option value="Tarjeta de Crédito">Tarjeta de Crédito</option>
                  <option value="Transferencia">Transferencia</option>
                </select>
              </div>
            </div>
            <div className="mb-4">
              <label className=" text-gray-700 font-medium flex items-center mb-1">
                <CategoryIcon className="mr-2" />
                Categorías:
              </label>
              <select
                name="category"
                value={formState.category}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="" disabled>Seleccione una categoría</option>
                {categories.map((cat) => (
                  <option key={cat.nombre} value={cat.nombre} style={{ color: cat.color }}>
                    {cat.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className=" text-gray-700 font-medium flex items-center mb-1">
                <PaymentIcon className="mr-2" />
                Cuotas:
              </label>
              <select
                name="cuotas"
                value={formState.cuotas}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                {[...Array(12).keys()].map((num) => (
                  <option key={num + 1} value={`${num + 1}`}>
                    {num + 1} Pago{num > 0 && "s"}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className=" text-gray-700 font-medium flex items-center mb-1">
                <NotesIcon className="mr-2" />
                Notas:
              </label>
              <textarea
                name="notes"
                value={formState.notes}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleClose}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-gray-600"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
        <div className="w-full max-w-2xl bg-white rounded-lg p-6 mx-4 overflow-y-auto max-h-600px shadow-lg mt-4">
          <h2 className="text-2xl font-semibold mb-6 text-gray-800">Expensas Cargadas</h2>
          <div style={{ height: "calc(100vh - 290px)", overflowY: "auto" }}>
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">Descripción</th>
                  <th className="py-2 px-4 border-b">Importe</th>
                  <th className="py-2 px-4 border-b">Formulario</th>
                  <th className="py-2 px-4 border-b">Borrar</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(expenses) && expenses.length > 0 ? (
                  expenses.map((expense) => (
                    <tr key={expense.id}>
                      <td className="py-2 px-4 border-b">{expense.detalle}</td>
                      <td className="py-2 px-4 border-b">{expense.monto}</td>
                      <td className="py-2 px-4 border-b">{expense.factura ? "Fact" : expense.recibo ? "Recibo" : expense.nota_credito ?  "NC" : 'N/A'}</td>
                      <td className="py-2 px-4 border-b">
                        <RemoveCircleOutlineIcon
                          sx={{ color: "red" }}
                          className="hover:cursor-pointer"
                          onClick={() => handleDelete(expense.id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="py-2 px-4 border-b text-center">
                      No hay expensas cargadas
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalExpensas;
