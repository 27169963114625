import React from 'react';
import Servicios from '../components/index/Servicios';
import { useStyles } from '../utils/funciones';
import Navbar from '../components/index/Navar';
import DondeEstamos from '../components/index/DondeEstamos';
import fotoPortada from '../../src/assets/images/portada.jpg'
import PropertyCard from '../components/index/CardPropiedad'
function Index() {
  const classes = useStyles();

  return (
    <div className="bg-blue-50 min-h-screen max-w-full">
        <Navbar/>
      <main className=" md:px-0 lg:px-0 xl:px-0 sm:w-11/12 sm:mx-auto">
      <section 
      className="bg-blue-900 text-white py-16 sm:min-w-full md:min-w-full text-center mt-4"
      style={{ 
        backgroundImage: `url(${fotoPortada})`, // Usa la imagen importada como fondo
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
      >   <h2 className="text-4xl font-bold mb-4">Encuentra tu hogar perfecto</h2>
          <p className="text-lg mb-4">Explora nuestras propiedades destacadas y encuentra la casa de tus sueños.</p>
      </section>


        <section className="text-white pt-2 text-center">
            <Servicios/>
        </section>

        <section className="py-4 mt-4">
          <h2 className="text-4xl font-bold mb-8 text-center text-blue-800">Propiedades En Venta</h2>

          <div className='flex flex-wrap justify-center'>
            <PropertyCard className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4' />
            <PropertyCard className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4' />
          </div>
  
        </section>
      </main>
      <DondeEstamos/>
      <footer className="bg-blue-900 text-white py-4 text-center">
        <p>&copy; 2024 Inmobiliaria Chiappero. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}
export default Index;
