import React from 'react';
import Navar from '../utils/Navar'
import EnhancedTable from '../components/pagos/Tabla'
import TitleNavar from '../components/pagos/Title'
import { useStyles } from '../utils/funciones';

const Dashboard = ({setIsAuthenticated}) => {
  const classes = useStyles();

  return (
    <>
    <div className="bg-gray-100  h-screen flex">
    <Navar setIsAuthenticated={setIsAuthenticated}/>
      <div className="w-full text-center flex-col mx-auto mt-20 -ml-20  ">
      <div className={classes.card}>
            <div className='p-4 '>
              <TitleNavar/>
            </div>
            <div className='p-4'>
              <EnhancedTable  />
            </div>          
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
