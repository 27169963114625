import React, { useState } from 'react';
import { Button, Modal, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import RoomIcon from '@mui/icons-material/Room';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import { ADD_INMUEBLE } from '../../utils/apiUrls';
import Swal from 'sweetalert2';
import { useStyles } from '../../utils/funciones';
import { formatearMonedaARS } from '../../utils/funciones';
const AgregarPropiedad = ({ open, setOpenModal, inmueble, setInmueble, setActualiza, actualiza }) => {
    const ariaLabel = { 'aria-label': 'description' };
    const classes = useStyles();
    const [formulario, setFormulario] = useState({
        direccion: inmueble ? inmueble.direccion : '',
        altura: inmueble ? inmueble.altura : '',
        piso: inmueble ? inmueble.piso : '',
        dpto: inmueble ? inmueble.unidad : '',
        provincia: inmueble ? inmueble.provincia : '',
        localidad: inmueble ? inmueble.localidad : '',
        dormitorios: inmueble ? inmueble.dormitorios : '',
        alquiler: inmueble ? inmueble.alquiler : '',
        precioalquiler: inmueble ? inmueble.alquiler_precio : '',
        expensas: inmueble ? inmueble.expensas : '',
        cochera: inmueble ? inmueble.cochera_tiene : '',
        patio: inmueble ? inmueble.patio : '',
        id: inmueble ? inmueble.id : null,
    });
    console.log(inmueble)

    const handleSave = (event) => {
        const { name, value } = event.target;
        setFormulario({ ...formulario, [name]: value });
    };

    const handleClose = () => {
        setOpenModal(false);
    };


    const handleCargarInmuebleExitoso = () => {
        handleClose();
        setActualiza(!actualiza)
        Swal.fire({
            title: 'Carga exitosa',
            text: 'El inmueble se ha cargado correctamente.',
            icon: 'success',
        });
    };

    const handleGuardar = () => {
        fetch(ADD_INMUEBLE, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formulario),
        })
            .then((response) => {
                if (response.ok) {
                    handleCargarInmuebleExitoso();
                } else {
                    // Handle error
                }
            })
            .catch((error) => {
                // Handle network or other errors
            });
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '1050px',
                        height: 'auto',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 1,
                    }}
                    style={{ maxHeight: '97%', overflow: 'auto' }}
                >
                    <div className={classes.card}>
                        <div className="w-full text-xl font-bold text-center py-2 text-white bg-blue-500">
                            {inmueble ? 'EDITAR PROPIEDAD':'AGREGAR PROPIEDADES' }  
                        </div>
                        <div className="w-8/12 mx-auto pb-5 flex-col">
                            <div className="mx-auto mt-4">
                                <div className="pt-4 mx-auto flex">
                                    <div className="flex-col">
                                        <div className="pb-2 flex items-center">
                                            <LocationOnIcon />
                                            <span className="font-semibold ml-2">Dirección</span>
                                        </div>
                                        <div className="w-72">
                                            <Input
                                                placeholder="Calle 12"
                                                name="direccion"
                                                onChange={handleSave}
                                                inputProps={ariaLabel}
                                                fullWidth
                                                value={formulario.direccion}
                                            />
                                        </div>
                                    </div>
                                    <div className="mx-8">
                                        <div className="pb-2 flex items-center">
                                            <HomeIcon />
                                            <span className="font-semibold ml-2">Altura</span>
                                        </div>
                                        <div className="w-full">
                                            <Input
                                                placeholder="688"
                                                name="altura"
                                                onChange={handleSave}
                                                inputProps={ariaLabel}
                                                fullWidth
                                                value={formulario.altura}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-3">
                                        <div className="pb-2 flex items-center">
                                            <ApartmentIcon />
                                            <span className="font-semibold ml-2">Piso</span>
                                        </div>
                                        <div className="w-16">
                                            <Input
                                                placeholder="5"
                                                name="piso"
                                                onChange={handleSave}
                                                inputProps={ariaLabel}
                                                fullWidth
                                                value={formulario.piso}
                                            />
                                        </div>
                                    </div>
                                    <div className="mx-8">
                                        <div className="pb-2 flex items-center">
                                            <RoomIcon />
                                            <span className="font-semibold ml-2">Dpto</span>
                                        </div>
                                        <div className="w-16">
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Input
                                                    placeholder="F"
                                                    name="dpto"
                                                    onChange={handleSave}
                                                    inputProps={ariaLabel}
                                                    value={formulario.dpto}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex pt-4"></div>
                            </div>
                            <div className="mx-auto flex">
                                <div>
                                    <div className="pb-2 flex items-center">
                                        <LocationOnIcon />
                                        <span className="font-semibold ml-2">Localidad</span>
                                    </div>
                                    <div className="w-full">
                                        <Input
                                            placeholder="Oliva"
                                            name="localidad"
                                            onChange={handleSave}
                                            inputProps={ariaLabel}
                                            fullWidth
                                            value={formulario.localidad}
                                        />
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <div className="pb-2 flex items-center">
                                        <LocationOnIcon />
                                        <span className="font-semibold ml-2">Provincia</span>
                                    </div>
                                    <div className="w-full">
                                        <Input
                                            placeholder="Córdoba"
                                            name="provincia"
                                            onChange={handleSave}
                                            inputProps={ariaLabel}
                                            fullWidth
                                            value={formulario.provincia}
                                        />
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <InputLabel id="demo-simple-select-standard-label">
                                        <div className="font-semibold">Dormitorios</div>
                                    </InputLabel>
                                    <div className="w-full">
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            onChange={handleSave}
                                            name="dormitorios"
                                            label="Age"
                                            size="small"
                                            fullWidth
                                            value={formulario.dormitorios}
                                        >
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                <div className="pl-8">
                                    <InputLabel id="demo-simple-select-standard-label">
                                        <div className="font-semibold">Alquiler o Venta</div>
                                    </InputLabel>
                                    <div className="w-36">
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            onChange={handleSave}
                                            name="alquiler"
                                            label="Age"
                                            size="small"
                                            fullWidth
                                            value={formulario.alquiler}
                                        >
                                            <MenuItem value={1}>Alquiler</MenuItem>
                                            <MenuItem value={0}>Venta</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            {formulario.alquiler == 1? (
                                <div className="flex mx-auto mt-4">
                                    <div className="">
                                    <div className="pb-2 flex items-center">
                                        <AttachMoneyRoundedIcon />
                                        <span className="font-semibold ml-2">Precio Alquiler</span>
                                    </div>                                        
                                    <div className="w-full">
                                            <Input
                                                placeholder="$50.000"
                                                name="precioalquiler"
                                                onChange={handleSave}
                                                inputProps={ariaLabel}
                                                fullWidth
                                                value={formulario.precioalquiler}
                                            />
                                        </div>
                                    </div>
                                    <div className="pl-8">
                                        <InputLabel id="demo-simple-select-standard-label">
                                            <div className="font-semibold">Expensas</div>
                                        </InputLabel>
                                        <div className="w-28">
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={formulario.expensas}
                                                onChange={handleSave}
                                                name="expensas"
                                                label="Age"
                                                size="small"
                                                fullWidth
                                            >
                                                <MenuItem value={1}>Si</MenuItem>
                                                <MenuItem value={0}>No</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="pl-8">
                                        <InputLabel id="demo-simple-select-standard-label">
                                            <div className="font-semibold">Cochera</div>
                                        </InputLabel>
                                        <div className="w-24">
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                size="small"
                                                id="demo-simple-select-standard"
                                                value={formulario.cochera}
                                                name="cochera"
                                                onChange={handleSave}
                                                label="Age"
                                                fullWidth
                                            >
                                                <MenuItem value={1}>Si</MenuItem>
                                                <MenuItem value={0}>No</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="pl-8">
                                        <InputLabel id="demo-simple-select-standard-label">
                                            <div className="font-semibold">Patio</div>
                                        </InputLabel>
                                        <div className="w-16">
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={formulario.patio}
                                                onChange={handleSave}
                                                name="patio"
                                                label="Age"
                                                size="small"
                                                fullWidth
                                            >
                                                <MenuItem value={1}>Si</MenuItem>
                                                <MenuItem value={0}>No</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div className="mt-4">
                                <Divider />
                                <div className="mt-4 mb-2 text-center flex">
                                    <div className="mx-auto">
                                        <Stack spacing={2} direction="row">
                                            <Button
                                                variant="contained"
                                                color="success"
                                                startIcon={<SaveIcon />}
                                                onClick={handleGuardar}
                                            >
                                                Guardar
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                startIcon={<CancelIcon />}
                                                onClick={handleClose}
                                            >
                                                Cancelar
                                            </Button>
                                        </Stack>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default AgregarPropiedad;

