import React from 'react';
import { useState } from 'react';
import Navar from '../utils/Navar'
import Index from '../components/propiedades/Index'
import AgregarPropiedad from '../components/propiedades/AgregarPropiedad';
import Title from '../components/propiedades/Title';
import AddHomeIcon from '@mui/icons-material/AddHome';
import { useStyles } from '../utils/funciones';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
const Dashboard = ({setIsAuthenticated}) => {
  const classes = useStyles();
  const [openAgregarPropiedad, setOpenAgregarPropiedad] = useState(false);
  const [propiedad, setPropiedad] = useState(false);
  
  const handleAgregarPropiedad = () => {
    setPropiedad()
    setOpenAgregarPropiedad(true)
  }

  return (
   
        <>
    <div className="bg-gray-100  h-screen flex">
    <Navar setIsAuthenticated={setIsAuthenticated} />
    <div className="w-full text-center flex-col mx-auto mt-20 -ml-20  ">
    <div className={classes.card}>
              <div className='p-4'>
              <Title  />
              <div className='text-end -mb-4'>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    // Aquí puedes agregar la lógica para manejar el evento onClick
                    handleAgregarPropiedad()
                  }}
                >
                  Agregar Propiedad
                </Button>
              </div>
              {openAgregarPropiedad  ? <AgregarPropiedad  open={openAgregarPropiedad} setOpenModal={setOpenAgregarPropiedad}   /> :null}

              </div>
              <div className='p-4'>
              <Index/>
              </div>          
            </div>
          </div>
        </div>
      </>
  );
};

export default Dashboard;
