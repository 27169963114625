import React, { useState, useEffect } from 'react';
import {  Modal, Box } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { BUSCAR_ALQUILERES_RENDIR, CARGAR_RENDICION} from '../../utils/apiUrls'
import { PDFDownloadLink } from '@react-pdf/renderer';
import MyDocument from './PdfRendicion';
import TablaRendicion from './TablaRendiciones'
import TablaGastos from './TablaGastos'
import TablaReintegros from './TablaReintegros'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { formatearMonedaARS, formatearFechaISO8601, sumarImportes } from '../../utils/funciones';
import { Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { makeStyles } from '@mui/styles'; // Cambia la importación de @mui/styles a @mui/material/styles
import ArticleIcon from '@mui/icons-material/Article';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
const useStyles = makeStyles((theme) => ({
    card: {
      boxShadow: `0px 8px 16px ${theme.palette.grey[400]}`,
      transition: 'transform 0.3s ease-in-out',
      '&:hover': {
        transform: 'scale(1.02)',
      },
      
    },

    title: {
      color: theme.palette.primary.dark,
      borderBottom: `2px solid ${theme.palette.primary.dark}`,
      marginBottom: theme.spacing(2), // Aquí se accede a theme.spacing
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
      margin: `${theme.spacing(2)}px 0`, // Aquí se accede a theme.spacing
    },
  }));
  
const ModalRendicion = ({ open, setOpenModal, propietario,  fechaSeleccionada, actualizarTabla, setActualizarTabla, setFechaSeleccionada}) => {
    const [imprimePdf, setImprimePdf] = useState(false)
    const [inmueble, setInmueble] = useState(null); // Estado para almacenar la gastos encontrada
    const [totalRendicion, setTotalRendicion] = useState(null);
    const [totalGastos, setTotalGastos] = useState(null);
    const [totalAlquiler, setTotalAlquiler] = useState(null);
    const [totalRendicionHonorarios, setTotalRendicionHonorarios] = useState(null);
    const [montoInteresesSinFormatear, setMontoInteresesSinFormatear] = useState(0)
    const [actualizar, setActualizar] = useState(false)
    const classes = useStyles();
    const [totalRendicionLocal, setTotalRendicionLocal] = useState(null)
    const [totalInmuebles, setTotalInmuebles] = useState(null)
    const [idRendicion, setIdRendicion] = useState(null)
    const [propiedadesAbiertas, setPropiedadesAbiertas] = useState(true);
    const [gastosAbiertos, setGastosAbiertos] = useState(true);
    const [reintegrosAbiertos, setReintegrosAbiertos] = useState(true);
    const [reintegros, setReintegros] = useState(null);
    const [totalReintegros, setTotalReintegros] = useState(null);
    const [gastos, setGastos] = useState(null)
    useEffect(() => {
        const pedirdatos =async () => {
            try {
                // Realizar la solicitud POST al servidor para buscar la gastos
                const response = await fetch(BUSCAR_ALQUILERES_RENDIR, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                      body: JSON.stringify({ propietario: propietario, fecha:fechaSeleccionada }),
                });
                if (response.ok) {
                  const data = await response.json();
                  if (data.data.length > 0) {
                    setInmueble(data.data);
                  } else {
                    setInmueble(null)
                  }
                  // Sumar los montos de los gastos
                } else {
                  console.error('Error al buscar la gastos:', response.status);
                  setInmueble(null); 
                }
              } catch (error) {
                console.error('Error en la solicitud:', error);
              }
              
        }
        pedirdatos()
        
    }, [ actualizar  ]);

    useEffect(() => {
        let totalAlquiler = 0;
        let total = 0;

        // Sumar los montos de los gastos
        if (inmueble && Array.isArray(inmueble)) {
            inmueble.forEach(itemInmueble => {
                totalAlquiler += parseFloat(itemInmueble.alquiler_precio);
                total += parseFloat(itemInmueble.alquiler_precio);
            });
            
            setTotalAlquiler(totalAlquiler)
            let honorarios = ((total * propietario.honorarios) / 100) 
            setTotalRendicionHonorarios(honorarios)
            setTotalRendicion(total)

        }
        if (gastos && gastos.length > 0) {
            let totalGastos = 0
            gastos.forEach(itemGasto => {
                totalGastos+= parseFloat(itemGasto.importe);
                total -= parseFloat(itemGasto.importe)

            });
            setTotalGastos(totalGastos)
            setTotalRendicion(total)

        }else {
            setTotalGastos(0)
        }
        if ( reintegros && reintegros.length > 0) {
            let totalReintegros = 0
            reintegros.forEach(item => {
                totalReintegros += parseFloat(item.importe);
                total += parseFloat(item.importe)
            });
            setTotalReintegros(totalReintegros)
            setTotalRendicion(total)

        } else {
            setTotalReintegros(0)

        }
    }, [inmueble, actualizar, gastos, reintegros]);

    const handleClose = () => {
        setActualizarTabla(!actualizarTabla)
        setOpenModal(!setOpenModal)

    };

    const handlePdf = async () => {
        try {
            // Realiza la solicitud POST
            const response = await fetch(CARGAR_RENDICION, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json' // Especifica el tipo de contenido del cuerpo de la solicitud
                },
                body: JSON.stringify({rendicion:totalInmuebles? totalInmuebles:inmueble, id_propietario:propietario.id_propietario, fechaSeleccionada:fechaSeleccionada }) // Aquí puedes pasar los datos que desees enviar en el cuerpo de la solicitud
            });
            // Verifica si la solicitud fue exitosa
            if (response.ok) {
                // Si la solicitud fue exitosa, puedes manejar la respuesta aquí
                const data = await response.json(); // Convierte la respuesta a formato JSON
                // Aquí puedes realizar las acciones necesarias con los datos recibidos del servidor
                setIdRendicion(data.nuevoId)

                setImprimePdf(true)

            } else {
                // Si la solicitud no fue exitosa, puedes manejar el error aquí
                console.error('Error al procesar la solicitud:', response.statusText);
            }
        } catch (error) {
            // Captura cualquier error de red u otro error durante la solicitud
            console.error('Error durante la solicitud:', error);
        }
    };

  
    const togglePropiedades = () => {
        setPropiedadesAbiertas(!propiedadesAbiertas);
    };

    const toggleGastos = () => {
        setGastosAbiertos(!gastosAbiertos);
    };
    const toggleReintegros = () => {
        setReintegrosAbiertos(!reintegrosAbiertos);
    };
    
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width:  imprimePdf ? 700 : 800,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: imprimePdf ? 2 : 4,
                        maxHeight: '99vh',
                        overflowY: 'auto' // Agrega una barra de desplazamiento vertical cuando sea necesario

                        
                    }}
                >
                
                   <div className=' h-[calc(100vh-100px)] '>
                    <>
                    {imprimePdf   ? 
                    null
                    : 
                    <>
                    <div className={classes.card + ' text-center bg-green-500 p-1 text-white font-bold text-xl'}>PROPIETARIO</div>
                    
                        <div className={classes.card + '  min-h-fit mb-2 pb-4  '}>
                    <div className='text-center items-center'>
        
                        <Avatar className={'mx-auto mt-2'} sx={{
                            boxShadow: '0px 0px 0px 2px #66bb6a', // Ajusta el tamaño y color del sombreado según sea necesario
                            borderRadius: '50%', // Hace que el borde sea circular
                        }}>
                            <PersonIcon />
                        </Avatar>
                        <p className=' mt-1 mb-2 font-semibold text-lg'>{propietario.nombre + " " + propietario.apellido}</p>
                    </div>
                    <div className='flex text-center -mt-4'>
                        <div className='mx-auto flex'>
                            <div className='flex pl-2 pt-2 my-2  w-36'>
                                <Avatar className={''} sx={{
                                    width: '30px',
                                    height: '30px',
                                    boxShadow: '0px 0px 0px 3px #039be5',
                                    borderRadius: '50%',
                                    background:'transparent'
                                }}>
                                    <ArticleIcon color='success'/>
                                </Avatar>
                                <div className='w-full'>
                                    <p className='text-sm -mt-2 '>DNI</p>
                                    <div style={{ borderBottom: '1px solid #039be5' }}></div> {/* Línea */}
                                    <p className='pl-2'>{propietario.dni }</p>
                                </div>
                            </div>
                            <div className='flex pl-2 pt-4  w-40'>
                                <Avatar className={''} sx={{
                                    width: '30px',
                                    height: '30px',
                                    boxShadow: '0px 0px 0px 3px #039be5',
                                    borderRadius: '50%',
                                    background:'transparent'
                                }}>
                                    <LocationOnIcon color='success'/>
                                </Avatar>
                                <div className='w-full'>
                                    <p className='text-sm -mt-2  '>Dirección</p>
                                    <div style={{ borderBottom: '1px solid #039be5' }}></div> {/* Línea */}
                                    <p className='pl-2'>{propietario.direccion }</p>
                                </div>
                            </div>
                        </div>
                        <div className='mx-auto flex -ml-3'>
                            <div className='flex pl-0 pt-2 my-2'>
                                <Avatar className={''} sx={{
                                    width: '30px',
                                    height: '30px',
                                    boxShadow: '0px 0px 0px 3px #039be5',
                                    borderRadius: '50%',
                                    background:'transparent'
                                }}>
                                    <PhoneIcon color='success'/>
                                </Avatar>
                                <div>
                                    <p className='text-sm -mt-2 '>Teléfono</p>
                                    <div style={{ borderBottom: '1px solid #039be5' }}></div> {/* Línea */}
                                    <p className='pl-2'>{propietario.telefono }</p>
                                </div>
                            </div>
                            <div className='flex pl-2 pt-4'>
                                <Avatar className={''} sx={{
                                    width: '30px',
                                    height: '30px',
                                    boxShadow: '0px 0px 0px 3px #039be5',
                                    borderRadius: '50%',
                                    background:'transparent'
                                }}>
                                    <EmailIcon color='success'/>
                                </Avatar>
                                <div>
                                    <p className='text-sm -mt-2  '>Correo electrónico</p>
                                    <div style={{ borderBottom: '1px solid #039be5' }}></div> {/* Línea */}
                                    <p className='pl-2'>{propietario.email }</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    </div>
                    <div className=''>
                    <div className={classes.card }>

                        <div className='mx-auto mb-4 pb-4 flex-col'>
                        <div>
                            <div className={classes.card + ' text-center bg-green-500 p-1 text-white font-bold text-xl cursor-pointer'} onClick={togglePropiedades}>{ propiedadesAbiertas ?<KeyboardDoubleArrowUpIcon/>:<KeyboardDoubleArrowDownIcon/> }  PROPIEDADES {totalAlquiler ? formatearMonedaARS(totalAlquiler):null}</div>
                            {propiedadesAbiertas && (
                                <div className='w-11/12  mx-auto'>
                                    <TablaRendicion propietarios={propietario} setTotalInmuebles={setTotalInmuebles} setTotalRendicionLocal={setTotalRendicionLocal} totalRendicionLocal={totalRendicionLocal} inmueble={inmueble} setInmueble={setInmueble} fechaSeleccionada={fechaSeleccionada} setFechaSeleccionada={setFechaSeleccionada} actualizaDatos={actualizar} setActualizaDatos={setActualizar} />
                                </div>
                            )}
                                <div className={classes.card + ' text-center text-red-500 p-1 border-2 border-white font-bold text-xl mt-4 cursor-pointer shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out'} onClick={toggleGastos}>
                                {gastosAbiertos ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />} GASTOS {totalGastos ? formatearMonedaARS(totalGastos) : formatearMonedaARS(0)}
                                </div>
                            {gastosAbiertos && (
                                <div className='w-11/12 mt-2 mx-auto'>
                                    <TablaGastos propietarios={propietario} setTotalInmuebles={setTotalInmuebles} setTotalRendicionLocal={setTotalRendicionLocal} totalRendicionLocal={totalRendicionLocal} inmueble={inmueble} setInmueble={setInmueble} fechaSeleccionada={fechaSeleccionada} setFechaSeleccionada={setFechaSeleccionada} actualizaDatos={actualizar} setActualizaDatos={setActualizar} setGastosDescontar={setGastos} />
                                </div>
                            )}
                            <div className={classes.card + ' text-center mt-4 text-green-500 p-1  font-bold text-xl cursor-pointer'} onClick={toggleReintegros}>{ reintegrosAbiertos ?<KeyboardDoubleArrowUpIcon/>:<KeyboardDoubleArrowDownIcon/> }  REINTEGROS {totalReintegros ? formatearMonedaARS(totalReintegros):formatearMonedaARS(0)}</div>
                            {reintegrosAbiertos && (
                                <div className='w-11/12 mt-2 mx-auto'>
                                    <TablaReintegros  reintegros={reintegros} setReintegros={setReintegros} propietarios={propietario} setTotalInmuebles={setTotalInmuebles} setTotalRendicionLocal={setTotalRendicionLocal} totalRendicionLocal={totalRendicionLocal} inmueble={inmueble} setInmueble={setInmueble} fechaSeleccionada={fechaSeleccionada} setFechaSeleccionada={setFechaSeleccionada} actualizaDatos={actualizar} setActualizaDatos={setActualizar} setGastosDescontar={setGastos} />
                                </div>
                            )}
                        </div>
                        <div className='flex mt-4 text-lg font-semibold text-center w-full mx-auto'>
                            <div className='w-1/3 mx-auto text-blue-500'>TOTAL A ENTREGAR: </div> 
                            {inmueble && Array.isArray(inmueble)  ? 
                                <div className='mx-auto pr-14 text-blue-500'>{totalRendicion ? formatearMonedaARS(totalRendicion): formatearMonedaARS(0)}</div>
                                : 
                                <div className='mx-auto pr-14 text-blue-500'>{totalRendicion ? formatearMonedaARS(totalRendicion): formatearMonedaARS(0)}</div>
                            }
                        </div>
                    </div>
                    </div>
                    </div>
                    </>
                    }
                    

                    <div className='flex my-4' >
                    {imprimePdf   ? 
                         <div className=' max-h-fit mt-40'>
                         <div className={classes.card}>
                         <div className='w-full p-1 mb-6 bg-green-600'> </div>
                         <div className='font-semibold text-3xl text-center text-green-500'>LA RENDICION SE REGISTRO CORRECTAMENTE!</div>
                         <div className='font-semibold pt-6 pl-10 text-center'>Para descargar el comprobante haga click en el siguiente link.</div>
                             <div className='w-full text-center'>
                                 <div className=' my-10 mx-auto  text-center bg-green-500 rounded-xl p-4 font-black text-white  max-w-fit'>
                                 <PDFDownloadLink document={<MyDocument reintegros={reintegros} totalReintegros={totalReintegros} totalRendicionHonorarios={totalRendicionHonorarios} honorarios={propietario.honorarios.toFixed(2)} idRendicion={idRendicion} handleClose={handleClose} open={open} fechaSeleccionada={fechaSeleccionada} totalRendicion={totalAlquiler} alquileres={inmueble} gastos={gastos} totalGastos={totalGastos} />} fileName={propietario.nombre + " "+ propietario.apellido + " " + fechaSeleccionada + ".pdf"}  >
                                         {({ blob, url, loading, error }) => (
                                           loading ? 
                                           'Cargando documento...' : 
                                           <>
                                           <div className='flex'>
                                             <CloudDownloadIcon />
                                             <div className='pl-2 '>Descargar PDF</div>
                                           </div>
 
                                         </>)}
                                     </PDFDownloadLink>
                                 </div>
                             </div>
                             <div className='w-full p-1  bg-green-600'> </div>
                         </div>
 
                       </div>
                    : 
                        <div className='p-3 mx-auto my-4 mr-8 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-md flex  cursor-pointer' onClick={handlePdf}>
                            <PictureAsPdfIcon className='mr-2'/> 
                            GENERAR RENDICION

                        </div>
                    }
                    {imprimePdf && inmueble && Array.isArray(inmueble)  ? 
                        null:
                        <div className='p-3 my-4 mx-auto  bg-red-600 hover:bg-red-700 text-white font-semibold rounded-md flex cursor-pointer' onClick={handleClose}><p className='pl-2'><CloseIcon className='-mt-1'/> CANCELAR</p></div>
                        }
                        </div>

                    </>
                    

                    
                   
               </div>
                </Box>
            </Modal>
        </div>
    );
};

export default ModalRendicion;
