import React, { useState, useEffect } from "react";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useStyles } from "../../utils/funciones";
import BuildingCard from "./Card";
import { BUSCAR_EDIFICIOS, BUSCAR_EXPENSAS } from '../../utils/apiUrls';
import ModalExpensas from "./ModalExpensas";
import Inmuebles from './Inmuebles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs'; // Importa dayjs

const Index = ({name, setName}) => {
  const classes = useStyles();
  const [buildings, setBuildings] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBuildings, setFilteredBuildings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openExpensas, setOpenExpensas] = useState(false);
  const [openInmuebles, setOpenInmuebles] = useState(false);
  const [id_edificio, setId_Edificio] = useState(null);
  const [expenses, setExpenses] = useState([]);
  const [fecha, setFecha] = useState(dayjs());

  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        const response = await fetch(BUSCAR_EDIFICIOS, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // Aquí puedes añadir cualquier dato que necesite tu API
          }),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data); // Asume que la respuesta es un array de edificios
        setBuildings(data.data); // Asume que la respuesta es un array de edificios
      } catch (error) {
        console.error("Error fetching buildings", error);
      }
    };

    fetchBuildings();
  }, []);

  useEffect(() => {
    setFilteredBuildings(
      buildings.filter(building =>
        building.edificio.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, buildings]);

  const fetchExpenses = async () => {
    const response = await fetch(`${BUSCAR_EXPENSAS}/${id_edificio}/${fecha}`);
    const data = await response.json();
    setExpenses(data.data);
    setIsModalOpen(true);
  };

  const handleFecha = (date) => {
    // Convertir el objeto Date a dayjs antes de actualizar el estado
    setFecha(dayjs(date));
  };

  useEffect(() => {
    if (openExpensas) {
      fetchExpenses();
    } else {
      setName('EDIFICIOS')
    }
  }, [openExpensas, fecha]);

  return (
    <>
      {!openExpensas ? (
        <>
          {openInmuebles ? (
            <Inmuebles 
              id_edificio={id_edificio} 
              setId_Edificio={setId_Edificio} 
              setIsModalOpen={setOpenInmuebles} 
              fecha={fecha} 
              setFecha={setFecha}  // Pasa la función setFecha
            />
          ) : (
            <div className="flex flex-col items-center mt-16 pb-10  ">
              <div className=" flex-row w-full">
                <div className='text-end items-end flex -mt-24 mb-6 mr-72'>
                  <div className='mr-2 mx-auto mb-2 font-bold'>MES Y AÑO: </div>
                  <LocalizationProvider size="small" dateAdapter={AdapterDayjs}>
                    <DateField 
                      format="MM/YYYY" 
                      size="small"
                      sx={{ width: '160px' }}
                      value={fecha} // Usa fecha convertida a dayjs
                      onChange={(date) => handleFecha(date)}
                      style={{maxWidth:'fit', width:'100px'}}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" size="small" />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <input
                  type="text"
                  placeholder="Buscar edificios"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="mb-4 p-2 border border-gray-300 rounded w-10/12 -mt-12 focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6">
                {filteredBuildings.length > 0 ? filteredBuildings.map((building, index) => (
                  <div key={index} className="w-full">
                    <BuildingCard
                      nombre={building.edificio}
                      localidad={building.provincia + ", " + building.localidad}
                      unidades={building.unidades}
                      direccion={building.direccion}
                      expenses={buildings}
                      id={building.id}
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      setId_Edificio={setId_Edificio}
                      setOpenInmuebles={setOpenInmuebles}
                      setOpenExpensas={setOpenExpensas}
                      openExpensas={openExpensas}
                      fecha={fecha}
                      setName={setName}
                    />
                  </div>
                )) : <p>No se encontraron edificios.</p>}
              </div>
            </div>
          )}
        </>
      ) : (
        <ModalExpensas 
          fecha={fecha} 
          setId_Edificio={setId_Edificio} 
          fetchExpenses={fetchExpenses} 
          id={id_edificio} 
          expenses={expenses} 
          openExpensas={openExpensas} 
          setOpenExpensas={setOpenExpensas} 
          setFecha={setFecha}
        />
      )}
    </>
  );
};

export default Index;
