import React, { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import {BUSCARSERVICIOS, ADD_SERVICIOS_PROPIEDAD, BUSCARSERVICIOSSELECCIONADOS} from '../../utils/apiUrls'
import { Modal, Button, Paper,  TableContainer, TableBody, TableRow, TableCell, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import AgregarServicio from '../propiedades/AgregarServicio'
import dayjs from 'dayjs'; // Importa dayjs
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import ModalFijarGasto from './ModalFijarGasto'
const SeleccionarServicios = ({ open, setOpenModal, inmueble, actualiza, setActualiza }) => {
  
  const [selectedServicios, setSelectedServicios] = useState([]);
  const [allServicios, setAllServicios] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [searchTextAll, setSearchTextAll] = useState('');
  const [searchTextSelected, setSearchTextSelected] = useState('');
  const [openModalAgregarServicio, setOpenModalAgregarServicio] = useState(false);
  const [actualizar, setActualizar] = useState(false)
  const [fecha, setFecha] = useState(dayjs());
  const [openModalFijar, setOpenMOdalFijar] = useState(false);
  const [gasto, setGasto] = useState(null);
  

  useEffect(() => {
      const fetchData = async () => {
        try {
          const resultado = await fetch(BUSCARSERVICIOS, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({id_inmueble:inmueble.id, fecha:fecha}),
          });
          const data = await resultado.json();
          setAllServicios(data)

          const resultado2 = await fetch(BUSCARSERVICIOSSELECCIONADOS, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({id_inmueble:inmueble.id, fecha:fecha}),
          });
          const data2 = await resultado2.json();
          setSelectedServicios(null)
          setSelectedServicios(data2)
        } catch (error) {
          console.log(error);
        }
      };
      fetchData(); 
  }, [actualizar]);

  const handleClose = () => {
    setOpenModal(false);
  };
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.common.white,
      cursor: 'pointer',
      position: 'sticky',
      top: 0,
      zIndex: 1, // Para que el encabezado quede encima del contenido desplazable
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));



  const handleAddToSelected = (servicio) => {
    console.log(servicio)
    servicio.fijar = 0
    if (servicio.id) {
    servicio.id_servicio = servicio.id
    }
    setAllServicios(allServicios.filter((r) => r !== servicio));
    setSelectedServicios([...selectedServicios, servicio]);
  };

  const handleOpenModalFijar = (servicio) => {
    // Actualizar el estado selectedServicios para reflejar el cambio en la propiedad 'fijar'
    const updatedSelectedServicios = selectedServicios.map(s => {
      if (s.id_servicio === servicio.id_servicio) {
        // Cambiar la propiedad 'fijar' a 1 si es 0, o a 0 si es 1
        console.log(s.fijar)
        s.fijar = s.fijar == 0 ? 1 : 0;
      }
      console.log(selectedServicios)
      return s;
    });
    setSelectedServicios(updatedSelectedServicios);
    
    // Mostrar/ocultar el modal según el estado actual
    setGasto(servicio);
  };

  const handleRemoveFromSelected = (servicio) => {
    setSelectedServicios(selectedServicios.filter((r) => r !== servicio));
    setAllServicios([...allServicios, servicio]);
    // Eliminar el valor del campo de objetivo cuando se quita el servicio
    const updatedInputValues = { ...inputValues };
    delete updatedInputValues[servicio.servicio];
    setInputValues(updatedInputValues);
  };
  const handleGuardar = () => {
    const objetivosDefinidos = selectedServicios.every((servicio) => {
      return (
        servicio.hasOwnProperty('n_cuenta') &&
        servicio.hasOwnProperty('n_factura') &&
        typeof servicio.n_cuenta === 'string' &&
        typeof servicio.n_factura === 'string' &&
        servicio.n_cuenta.trim() !== '' &&
        servicio.n_factura.trim() !== ''
      );
    });
  
    if (objetivosDefinidos) {
      // Todos los servicios tienen objetivos definidos, puedes continuar con la acción de guardar.
      const fetchData = async () => {
        try {
          const resultado = await fetch(ADD_SERVICIOS_PROPIEDAD, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ id_inmueble: inmueble.id, selectedServicios, fecha:fecha }),
          });
          const data = await resultado.json();
        } catch (error) {
          console.log(error);
        }
      };
      fetchData(); // Llamar a la función para cargar los datos al cargar el componente
      handleClose();
    } else {
   
      Swal.fire({
        target: document.getElementById('form-modal'),
        title: 'Aviso',
        text: 'Por favor, complete los campos solicitados.',
        icon: 'warning',
        buttons: ['Aceptar'],
        modal: true,
        backdrop: false,
        position: 'absolute',
        zIndex: 1000,
      }).then(() => {
        // El usuario cerró el SweetAlert, cierra el modal
      });
    }
  };

  const handleAgregarServicio = () => {
    setOpenModalAgregarServicio(true)
  }

  const handleInputChange = (servicioName, objetivo, campo) => {
    const updatedServicios = selectedServicios.map(servicio => {
      if (servicio.servicio === servicioName) {
        return {
          ...servicio,
          [campo]: objetivo
        };
      }
      return servicio;
    });
  
    setSelectedServicios(updatedServicios);
  };

  const handleFecha = (date) => {
    // Convertir el objeto Date a dayjs antes de actualizar el estado
    setFecha(dayjs(date));
    setActualizar(!actualizar)
  };

  return (
      
    <>
     <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box
    sx={ {
      position: 'relative',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: "90%",
      height: "auto",
      maxHeight:'screeen',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 1,
    }}
    style={{ maxHeight: '97%', overflow: 'auto' }}
    >
        <div className='w-full'>

          <h1 className='w-full text-xl font-bold text-center py-2 text-white bg-blue-500 '>GASTOS  </h1>
      
            <div className='w-full text-xl font-bold text-center py-1 text-black '>
                {inmueble.direccion + ' ' +  inmueble.altura + ' - Piso: ' + inmueble.piso + ' Unidad: ' + inmueble.unidad }
            </div>
            <Divider  color='secondary'/>
            <Divider  color='secondary'/>
            <Divider  color='secondary'/>
          <div className='my-2 -mb-1 text-center flex  font-medium'>  
            <div className='mx-auto flex-col w-full'>
              <div className='text-center mx-auto '>
                <Button variant='outlined' sx={{padding:'6px'}} color='success' onClick={() => {handleAgregarServicio()}}>AGREGAR GASTOS</Button>  
              </div>
              <div className='flex  text-end -mt-10 ml-3' >
                <p className='mt-2 font-bold pr-2'>MES Y AÑO: </p>
                <LocalizationProvider size="small"  dateAdapter={AdapterDayjs}>
                  <DateField 
                    format="MM/YYYY" 
                    size="small"
                    sx={{ width: '160px' }}
                    value={fecha} // Usa fecha convertida a dayjs
                    onChange={(date) => handleFecha(date)}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" size="small" />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }} className='my-1'>
            <div className='w-4/12 m-2'>
              <h3 className='bg-blue-400 py-2 text-white font-bold text-center mb-1'>GASTOS DISPONIBLES</h3>
              <TextField
                label="Buscar"
                variant="outlined"
                fullWidth
                value={searchTextAll}
                onChange={(e) => setSearchTextAll(e.target.value)}
              />
              <TableContainer component={Paper} style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <table className="w-full mt-2">
                  <thead className=" bg-gray-700 w-full text-white font-semibold" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                    <TableRow>
                      <StyledTableCell align='center'>GASTOS</StyledTableCell>
                      <StyledTableCell align='center'>ACCIONES</StyledTableCell>
                    </TableRow>
                  </thead>
                  <TableBody>
                    {allServicios.length > 0
                      ? allServicios
                          .filter((servicio) =>
                            servicio.servicio.toLowerCase().includes(searchTextAll.toLowerCase())
                          )
                          .map((servicio) => (
                            <TableRow sx={{ maxHeight: '20px', padding:'0px' }} key={servicio.id}>
                              <TableCell sx={{ maxHeight: '20px', padding:'0px' }} align='center'>{servicio.servicio}</TableCell>
                              <TableCell sx={{ maxHeight: '20px', padding:'0px' }} align='center' key={servicio.id}>
                                <Button color='success'  key={servicio.id} onClick={() => handleAddToSelected(servicio)}>Agregar</Button>
                              </TableCell>
                            </TableRow>
                          ))
                      : null}
                  </TableBody>
                </table>
              </TableContainer>

            </div>
            <div className='w-8/12 m-2'>
              <h3 className='bg-blue-400 py-2 text-white font-bold text-center mb-1'>GASTOS SELECCIONADOS</h3>
              <TextField
                label="Buscar"
                variant="outlined"
                fullWidth
                value={searchTextSelected}
                onChange={(e) => setSearchTextSelected(e.target.value)}
              />
              <TableContainer component={Paper} style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <table className="w-full mt-2">
                  <thead className=" bg-gray-700 w-full text-white font-semibold" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                    <TableRow>
                      <StyledTableCell align='center'>GASTOS</StyledTableCell>
                      <StyledTableCell align='center'>N° CUENTA</StyledTableCell>
                      <StyledTableCell align='center'>N° FACTURACION</StyledTableCell>
                      <StyledTableCell align='center'>MONTO</StyledTableCell>
                      <StyledTableCell align='center'>ACCIONES</StyledTableCell>
                    </TableRow>
                  </thead>
                  <TableBody>
                  {selectedServicios ?
                    selectedServicios
                      .filter((servicio) => servicio.servicio.toLowerCase().includes(searchTextSelected.toLowerCase()))
                      .map((servicio) => (
                        <React.Fragment key={servicio.id_servicio}>
                          <TableRow  
                            sx={{ 
                              backgroundColor: servicio.fijar == 1 ? 'rgb(187 247 208)' : 'transparent',
                            }}
                          >
                            <TableCell  sx={{ padding:'0px', maxHeight:10 }} align='center'>{servicio.servicio}</TableCell>
                            <TableCell  sx={{ padding:'0px' }} align='center'>
                                <TextField
                                  size="small"
                                  value={servicio.n_cuenta}
                                  sx={{ width: '17ch', margin:'0px', padding:'0px' }}
                                  onChange={(e) => handleInputChange(servicio.servicio, e.target.value, 'n_cuenta')}
                                />
                    
                              </TableCell>
                              
                              <TableCell  sx={{ padding:'0px'}} align='center'>
                                <TextField
                                  value={servicio.n_factura }
                                  size="small"
                                  sx={{ width: '17ch', margin:'0px', padding:'0px' }}
                                  onChange={(e) => handleInputChange(servicio.servicio, e.target.value, 'n_factura')}
                                />
                        
                              </TableCell>
                                 
                              <TableCell  sx={{ padding:'0px'}} align='center'>
                                <TextField
                                  value={servicio.monto}
                                  size="small"
                                  sx={{ width: '17ch', margin:'0px', padding:'0px' }}
                                  onChange={(e) => handleInputChange(servicio.servicio, e.target.value, 'monto')}
                                />
                        
                              </TableCell>
                 
                            <TableCell 
                              align='center'
                              sx={{ marginBottom:'10px', minHeight:'10px', padding:'0px'}}
                            >
                              {servicio.fijar == 1 ? 
                              null
                              :                               
                                <Button onClick={() => handleOpenModalFijar(servicio)}>
                                  <div className='bg-green-500 text-white rounded px-2 py-1 font-semibold'>FIJAR</div>
                                </Button>  
                              }

                              <Button onClick={() => handleRemoveFromSelected(servicio)}>
                                <div className='bg-red-500 text-white rounded px-2 py-1 font-semibold'>Eliminar</div>
                              </Button>

                            </TableCell>
                          </TableRow>
  
                        </React.Fragment>
                      )): null}
                  
                  </TableBody>
                </table>
              </TableContainer>
            </div>
          </div>

        </div>
        <Divider color={'secondary'}/>
          <Divider color={'secondary'}/>
          <Divider color={'secondary'}/>
          <div className='flex'>
          <div className='mx-auto flex my-4'>
              <div className='mr-4'>
              <Button variant="contained" color="primary" onClick={handleGuardar}>
                Guardar
              </Button>
            </div>
            <div className='ml-4 '>
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancelar
              </Button>
            </div>
          </div>
          </div>
         {openModalAgregarServicio ? <AgregarServicio  open={openModalAgregarServicio} setOpenModal={setOpenModalAgregarServicio} setActualizar={setActualizar} actualizar={actualizar} editar={false}  /> :null}
         {openModalFijar ? <ModalFijarGasto gasto={gasto} open={openModalFijar} setOpenModal={setOpenMOdalFijar} setActualizar={setActualizar} inmueble={inmueble} actualizar={actualizar} editar={false}  /> :null}
        </Box>
        
      </Modal>

    </>
  );

};

export default  SeleccionarServicios;
