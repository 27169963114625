import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  CssBaseline
} from "@mui/material";
import { INMUEBLES_DEUDORES,SEARCH_INMUEBLE_ID } from "../../utils/apiUrls";
import ModalPagos from "../pagos/Index";
import dayjs from "dayjs";
const Deudores = () => {
  const [deudores, setDeudores] = useState([]);
  const [inmueble, setInmueble] = useState(null);
  const [abrirModal, setAbrirModal] = useState(false);
  const fechaSeleccionada = dayjs(); // Inicializa fechaSeleccionada como una instancia de Dayjs

  useEffect(() => {
      // Fetch data from the backend
      fetch(INMUEBLES_DEUDORES)
          .then(response => response.json())
          .then(data => setDeudores(data));
  }, []);

  const handlePagar = (inmu) => {
      fetch(SEARCH_INMUEBLE_ID, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: inmu.id })
      })
          .then(response => response.json())
          .then(data => {
              setInmueble(data.data[0]);
              setAbrirModal(true);
          });
  };

  return (
      <TableContainer
          component={Paper}
          sx={{
              maxHeight: 500,
              marginTop: 2,
              backgroundColor: "rgb(48, 108, 193)"
          }}
      >
          <Typography
              variant="h8"
              component="div"
              sx={{ flexGrow: 1, marginY: 1, color: "white" }}
          >
              Deuda de Alquiler en el Mes Actual
          </Typography>
          {abrirModal && inmueble && (
              <ModalPagos open={abrirModal} setOpenModal={setAbrirModal} inmueble={inmueble} fechaSeleccionada={fechaSeleccionada} />
          )}
          <TableContainer component={Paper} sx={{ maxHeight: 240, marginTop: 0 }}>
              <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 250 }}>
                  <TableHead>
                      <TableRow>
                          <TableCell sx={{}}>Dirección</TableCell>
                          <TableCell sx={{ textAlign: "center" }}>Registrar Pago</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {deudores.map((propiedad, index) => (
                          <TableRow
                              key={propiedad.id_propiedad}
                              sx={{
                                  backgroundColor: index % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "white",
                                  "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.1)"
                                  }
                              }}
                          >
                              <TableCell sx={{ fontSize: 12 }}>
                                  {propiedad.direccion + " " + propiedad.altura + " " + propiedad.piso + " " + propiedad.unidad}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                  <button
                                      onClick={() => handlePagar(propiedad)}
                                      className="bg-green-600 p-1.5 rounded hover:bg-green-800 text-white uppercase text-xs font-bold"
                                  >
                                      Pagar
                                  </button>
                              </TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
      </TableContainer>
  );
};

export default Deudores;
