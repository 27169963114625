import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from './../../assets/images/logo.png';
import facebook from './../../assets/images/facebook.png';
import instagram from './../../assets/images/instagram.png';
import firma from './../../assets/images/firma.png';
import { formatearFechaISO8601, formatearMonedaARS, formatearHora, formatearFechaCorta } from '../../utils/funciones';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFF',
    padding: 30,
  },
  section: {
    flexGrow: 1,
    textAlign: 'center',
  },
  logoContainer: {
    marginBottom: 10,
  },
  logo: {
    width: 75,
    height: 75,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  firma: {
    width: 150, // Ancho de la firma
    height: 150, // Alto de la firma
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom:150
  },
  iconos: {
    height:'8px',
    width:'8px',
    position: 'relative',
    top: 2, // Ajusta la posición vertical del icono con el texto
    left: 5, // Ajusta la posición horizontal del icono con el texto
  },
  title: {
    fontSize: 19,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#333',
    borderBottom: '2pt solid #333',
    paddingBottom: 5,
    marginTop:5,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#666',
  },
  encabezados: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#666',
    marginTop:'10px'
  },


  text: {
    fontSize: 12,
    marginBottom: 5,
    color: '#555',
  },
  footer: {
    position: 'absolute',
    bottom: 120,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 10,
    color: '#999',
    marginTop: 20,
  },
  socialLinks: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  socialLinkstext: {
    fontSize: 8,
    marginTop: 5,
    color: '#666',
  },
  separator: {
    borderBottom: '1pt solid #999',
    marginBottom: 10,
  },
  headerCell: {
    padding: 5,
    fontSize: 9,
    color: '#000',
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    flex: 1,
    textAlign: 'center',
    flexGrow: 1 // Esta propiedad hará que la celda ocupe todo el espacio disponible
  },
  dataCell: {
    padding: 2,
    fontSize: 8,
    color: '#555',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    flex: 1,
    textAlign: 'center',
    flexGrow: 1 // Esta propiedad hará que la celda ocupe todo el espacio disponible
  },
  smallCell:{
    maxWidth:'50px', // Ancho más estrecho para las celdas pequeñas
  }

});
function fechaFinContrato(fechaInicio, duracionContrato) {
try {
  var fechaFinContrato = new Date(fechaInicio);
  fechaFinContrato.setFullYear(fechaFinContrato.getFullYear() + duracionContrato);
  fechaFinContrato = formatearFechaISO8601(fechaFinContrato)
  console.log(fechaInicio)
  console.log(fechaFinContrato)
  console.log(duracionContrato)
  return fechaFinContrato; // Formatear la fecha como una cadena legible

} catch (error) {
  return error;
  
}
}


function calcularFechaProximoAumento(fechaInicio, tipoAumento, duracionContrato) {
  // Convertir la fecha de inicio a un objeto Date
  fechaInicio = new Date(fechaInicio);
  
  // Calcular la fecha actual
  var fechaActual = new Date();

  // Calcular la cantidad de meses entre la fecha de inicio y la fecha actual
  var mesesTranscurridos = (fechaActual.getFullYear() - fechaInicio.getFullYear()) * 12;
  mesesTranscurridos -= fechaInicio.getMonth();
  mesesTranscurridos += fechaActual.getMonth();

  // Calcular el número total de aumentos aplicados hasta ahora
  var aumentosAplicados = Math.floor(mesesTranscurridos / tipoAumento);

  // Calcular la fecha del próximo aumento
  var proximoAumento = new Date(fechaInicio);
  proximoAumento.setMonth(proximoAumento.getMonth() + (aumentosAplicados + 1) * tipoAumento);

  // Verificar si el próximo aumento supera la duración del contrato
  var fechaFinContrato = new Date(fechaInicio);
  fechaFinContrato.setFullYear(fechaFinContrato.getFullYear() + duracionContrato);
  if (proximoAumento > fechaFinContrato) {
      return "-";
  } else {
      return formatearFechaISO8601(proximoAumento); // Formatear la fecha como una cadena legible
  }
}

const fechaActual = new Date()
const MyDocument = ({reintegros,totalReintegros, alquileres, totalRendicion, fechaSeleccionada, idRendicion, honorarios, totalRendicionHonorarios, gastos, totalGastos}) => (
   <>
   {console.log('desde pdf')}
   {console.log(alquileres)}
    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.logoContainer}>
          <Image src={logo} style={styles.logo} />
        </View>
        <Text style={styles.subtitle}>Inmobiliaria Chiappero</Text>
        <Text style={styles.text}>
            <Text style={styles.text}>N° de comprobante de rendicion: {idRendicion}</Text>
            <Text style={styles.text}> // Fecha de registro: {formatearFechaISO8601(fechaActual)} - {formatearHora(fechaSeleccionada)} Hs.</Text>
        </Text>
        <Text style={styles.title}>RENDICION DE ALQUILERES</Text>
        <Text style={styles.text}>RECIBO POR MEDIO DEL SR. CHIAPPERO FERNANDO, M.P. CPI - 4745,  </Text>
        <Text style={styles.text}>LOS ALQUILERES QUE SE DETALLAN A CONTINUACION:</Text> 
         {/* Recorre las facturas y crea textos */}
         <Text style={styles.encabezados}>ALQUILERES</Text>
         {alquileres ? 
      <View>
        {/* Encabezado de la tabla */}
        <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 2 }}>
          <Text style={styles.headerCell}>Dirección</Text>
          <Text style={[styles.headerCell, styles.smallCell]}>Altura</Text>
          <Text style={[styles.headerCell, styles.smallCell]}>Piso</Text>
          <Text style={[styles.headerCell, styles.smallCell]}>Unidad</Text>
          <Text style={[styles.headerCell]}>Liquidación</Text>
          <Text style={[styles.headerCell]}>Inicio </Text>
          <Text style={[styles.headerCell]}>Duración </Text>
          <Text style={[styles.headerCell]}>Fin </Text>
          <Text style={[styles.headerCell]}> Aumento</Text>
          <Text style={styles.headerCell}>Monto</Text>
        </View>
      
        {/* Datos de los alquileres */}
        {alquileres.map((alquiler, index) => (
          <View key={index} style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Text style={styles.dataCell}>{alquiler.direccion}</Text>
              <Text style={[styles.dataCell, styles.smallCell]}>{alquiler.altura}</Text>
              <Text style={[styles.dataCell, styles.smallCell]}>{alquiler.piso}</Text>
              <Text style={[styles.dataCell, styles.smallCell]}>{alquiler.unidad}</Text>
              <Text style={[styles.dataCell]}>{formatearFechaCorta(fechaSeleccionada)}</Text>
              <Text style={[styles.dataCell]}>{formatearFechaISO8601(alquiler.fecha_inicio)}</Text>
              <Text style={[styles.dataCell]}>{alquiler.duracion_contrato} Años</Text>
              <Text style={[styles.dataCell]}>{fechaFinContrato(alquiler.fecha_inicio, alquiler.duracion_contrato)}</Text>
              <Text style={[styles.dataCell]}>{calcularFechaProximoAumento(alquiler.fecha_inicio, alquiler.tipo_aumento, alquiler.duracion_contrato)}</Text>
              <Text style={styles.dataCell}>{formatearMonedaARS(parseFloat(alquiler.alquiler_precio))}</Text>
          </View>
        ))}
            <Text style={{            
            padding: 2,  
            fontSize: 12,
            fontWeight:'black',
            marginBottom: 0,
            marginTop:2,
            }}>
              TOTAL ALQUILERES:  {formatearMonedaARS(parseFloat(totalRendicion))} 
        </Text>
      </View>
    
        : null}         
       {/* Verifica si factura es un array antes de mapearlo */}
       {}
       {gastos && gastos.length > 0 ? (
  <>
    <Text style={styles.encabezados}>GASTOS</Text>
    <View wrap={false}>
      <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 0 }}>
        <Text style={styles.headerCell}>Detalle</Text>
        <Text style={styles.headerCell}>Importe</Text>
      </View>
      {gastos.map((gasto, index) => (
        <View key={index} style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Text style={styles.dataCell}>{gasto.detalle}</Text>
          <Text style={styles.dataCell}>{formatearMonedaARS(gasto.importe)}</Text>
        </View>
      ))}
      <Text style={{ padding: 2, fontSize: 12, fontWeight: 'black', marginBottom: 0, marginTop: 2 }}>
        TOTAL GASTOS: {formatearMonedaARS(parseFloat(totalGastos ? totalGastos : 0))}
      </Text>
    </View>
  </>
) : null}

{reintegros && reintegros.length > 0 ? (
  <>
    <Text style={styles.encabezados}>REINTEGROS</Text>
    <View wrap={false} break>
      <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 0 }}>
        <Text style={styles.headerCell}>Detalle</Text>
        <Text style={styles.headerCell}>Importe</Text>
      </View>
      {reintegros.map((reintegro, index) => (
        <View key={index} style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Text style={styles.dataCell}>{reintegro.detalle}</Text>
          <Text style={styles.dataCell}>{formatearMonedaARS(reintegro.importe)}</Text>
        </View>
      ))}
      <Text style={{ padding: 2, fontSize: 12, fontWeight: 'black', marginBottom: 0, marginTop: 2 }}>
        TOTAL REINTEGROS: {formatearMonedaARS(parseFloat(totalReintegros ? totalReintegros : 0))}
      </Text>
    </View>
  </>
) : null}

<View style={styles.socialLinks} break>
  <Text style={{ padding: 2, fontSize: 12, fontWeight: 'black', marginBottom: 0, marginTop: 2 }}>
    HONORARIOS PROFESIONALES {honorarios}% {formatearMonedaARS(parseFloat(totalRendicionHonorarios ? totalRendicionHonorarios : 0))}
  </Text>
  <Text style={{ padding: 2, fontSize: 16, fontWeight: 'black', marginBottom: 5 }}>
    TOTAL A ENTREGAR: {formatearMonedaARS(parseFloat(totalRendicion - (totalRendicionHonorarios ? totalRendicionHonorarios : 0) + (totalReintegros ? totalReintegros : 0) - (totalGastos ? totalGastos : 0)))}
  </Text>
  <View style={styles.separator} />
  <Text style={styles.socialLinkstext}>Para más información, contáctanos:</Text>
  <Text style={styles.socialLinkstext}>Horarios de Atención: Lunes a Viernes de 9:00 a 18:00 hs</Text>
  <Text style={styles.socialLinkstext}>Email: Inmobiliariachiappero@gmail.com</Text>
  <Text style={styles.socialLinkstext}>Teléfono: (03532) 67-8233</Text>
  <Text style={styles.socialLinkstext}>
    <Image src={facebook} style={styles.iconos} />
    - Síguenos en Facebook: @Inmobiliariachiappero
  </Text>
  <Text style={styles.socialLinkstext}>
    <Image src={instagram} style={styles.iconos} />
    - Síguenos en Instagram: @Inmobiliariachiappero
  </Text>
</View>
</View>
</Page>
</Document>
</>
);

export default MyDocument;
