import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Divider, Input, InputAdornment } from '@mui/material';
import Stack from '@mui/material/Stack';
import {ADD_PROPIETARIO} from '../../utils/apiUrls'
import Swal from 'sweetalert2';
import dayjs from 'dayjs'; // Importa dayjs
import { useStyles } from '../../utils/funciones';
import Avatar from '@mui/material/Avatar';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { AccountCircle, Home, Phone, Mail, Event } from '@mui/icons-material'; // Importa iconos de Material-UI
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const AgregarPropietario = ({ open, setOpenModal, actualizar ,setActualizar, editPropietario }) => {
    const ariaLabel = { 'aria-label': 'description' };
    const classes = useStyles();

    const [formulario, setFormulario] = useState({
        direccion: editPropietario ? editPropietario.direccion : '',
        fecha_nacimiento: editPropietario ? editPropietario.fecha_nacimiento : new Date(),
        nombre: editPropietario ? editPropietario.nombre :'',
        apellido: editPropietario ? editPropietario.apellido :'',
        dni: editPropietario ? editPropietario.dni :'',
        telefono: editPropietario ? editPropietario.telefono :'',
        email: editPropietario ? editPropietario.email :'',
        honorarios: editPropietario ? editPropietario.honorarios : 0,
        id_propietario: editPropietario ? editPropietario.id_propietario :null,
      });
   
  const handleSave = (event) => {
     const { name, value } = event.target;
     console.log(event)
    setFormulario({ ...formulario, [name]: value });
  };
 
  const handleClose = () => {
    setOpenModal(false);
  };
  
  const handleCargarInmuebleExitoso = () => {
    // Muestra una alerta de SweetAlert2
    setActualizar(!actualizar)
    handleClose();
        if (formulario.id_propietario) {
            Swal.fire({
                title: 'Modificacion exitosa',
                text: 'El PROPIETARIO se ha MODIFICADO correctamente.',
                icon: 'success',
              });
        } else {
            Swal.fire({
                title: 'Carga exitosa',
                text: 'El PROPIETARIO se ha CREADO correctamente.',
                icon: 'success',
              });
        }
        
  }

  


  const handleGuardar = () => {
      formulario.fecha_nacimiento = dayjs(formulario.fecha_nacimiento).format('YYYY-MM-DD')
      // Realiza una solicitud POST a tu API
    fetch(ADD_PROPIETARIO, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formulario),
    })
      .then((response) => {
        if (response.ok) {
            handleCargarInmuebleExitoso();
        } else {
          // La solicitud falló, maneja el error, por ejemplo, mostrando un mensaje de error
        }
      })
      .catch((error) => {
        // Maneja errores de red u otros errores
      });
  };

 
 
   return (
      
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "1050px",
            height: "auto",
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
          }}
          style={{ maxHeight: '97%', overflow: 'auto' }}
        > 
     <div className={classes.card}>
                        <div className='w-full text-xl font-bold text-center py-2 text-white bg-gray-800 cursor-default'>
                            {editPropietario ? 'EDITAR' : '+ AGREGAR' } PROPIETARIO
                        </div>
                        <div className='w-full flex py-4'>
                          <div className='mx-auto'>
                          <Stack direction="row" spacing={2}>
                            <Avatar sx={{ height:'60px', width:'60px'}}>
                              <PersonRoundedIcon sx={{ height:'50px', width:'50px'}}/>
                            </Avatar>
                          </Stack>
                          </div>
                        </div>
                        <div className='w-full '>
                            <div className='mx-auto w-3/4 '>
                                <div className='mx-auto w-fit '> </div>
                                <div className=' min-w-max flex '>
                                    <div className='w-full pr-4'>
                                        <div className='pb-2 font-semibold'>Nombre</div>
                                        <div className='w-full'>
                                            <Input
                                                value={formulario.nombre}
                                                name='nombre'
                                                onChange={handleSave}
                                                inputProps={ariaLabel}
                                                fullWidth
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <AccountCircle />
                                                    </InputAdornment>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='w-full pl-4'>
                                        <div className='pb-2  font-semibold'>Apellido</div>
                                        <div className='w-full'>
                                            <Input
                                                value={formulario.apellido}
                                                name='apellido'
                                                onChange={handleSave}
                                                inputProps={ariaLabel}
                                                fullWidth
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <AccountCircle />
                                                    </InputAdornment>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-4 flex w-full'>
                                    <div className='w-1/2 pr-4'>
                                        <div className='pb-2 font-semibold'>Dni</div>
                                        <div className='w-full'>
                                            <Input
                                                value={formulario.dni != '' ? formulario.dni : null}
                                                name='dni'
                                                onChange={handleSave}
                                                inputProps={ariaLabel}
                                                fullWidth
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <AccountCircle />
                                                    </InputAdornment>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='w-1/2 pl-4'>
                                        <div className='pb-2 font-semibold'>Direccion</div>
                                        <div className='w-full'>
                                            <Input
                                                value={formulario.direccion}
                                                name='direccion'
                                                onChange={handleSave}
                                                inputProps={ariaLabel}
                                                fullWidth
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Home />
                                                    </InputAdornment>
                                                }
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='flex pt-4'>
                                    <div className='w-full pr-4'>
                                        <div className='pb-2 font-semibold'>Telefono</div>
                                        <div className='w-full'>
                                            <Input
                                                name='telefono'
                                                value={formulario.telefono }
                                                onChange={handleSave}
                                                inputProps={ariaLabel}
                                                fullWidth
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Phone />
                                                    </InputAdornment>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='w-full pl-4'>
                                        <div className='pb-2 font-semibold'>Email</div>
                                        <div className='w-full'>
                                            <Input
                                                name='email'
                                                value={formulario.email}
                                                onChange={handleSave}
                                                inputProps={ariaLabel}
                                                fullWidth
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Mail />
                                                    </InputAdornment>
                                                }
                                            />
                                        </div>
                                    </div>
                                  
                                </div>
                                <div className='flex pt-4'>
                                <div className='w-1/2'>
                                        <div className='pb-2 font-semibold'>Honorarios</div>
                                        <div className='w-full'>
                                            <Input
                                                name='honorarios'
                                                value={formulario.honorarios}
                                                onChange={handleSave}
                                                inputProps={ariaLabel}
                                                fullWidth
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <RequestQuoteIcon />
                                                    </InputAdornment>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-4'></div>
                                <Divider />
                                <div className='mt-4'>
                                    <Divider />
                                    <div className='mt-4 pb-4 text-center flex'>
                                        <div className='mx-auto'>
                                            <Stack spacing={2} direction="row">
                                                <Button variant="contained" color='primary' onClick={handleGuardar}> {editPropietario ? 'MODIFICAR' : 'GUARDAR'} </Button>
                                                <Button variant="contained" color='secondary' onClick={handleClose}>Cancelar</Button>
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
            
        </Box>
      </Modal>

    </>
  );
};

export default AgregarPropietario;