import React, { useState } from "react";
import { Modal, TextField, Button, Box } from "@mui/material";
import { REGISTRAR_GASTO } from '../../utils/apiUrls';

const AgregarGastosModal = ({ isOpen, onClose, id_propietario, fecha, actualizar, setActualizar }) => {
  const [detalle, setDetalle] = useState("");
  const [monto, setMonto] = useState("");


  const handleGuardar = () => {
    // Datos de ejemplo de gastos
    const gastos = {
      detalle: detalle,
      monto: monto,
      id_propietario: id_propietario,
      fecha:fecha
    };
  
    // Configuración del request
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(gastos)
    };
  
    // Realizar la solicitud
    fetch(REGISTRAR_GASTO, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al registrar el gasto');
        }
        console.log('Gasto registrado exitosamente');
        setActualizar(!actualizar)
        onClose(); // Cierra el modal después de guardar exitosamente
      })
      .catch(error => {
        console.error('Error:', error);
        // Aquí puedes manejar el error de acuerdo a tus necesidades
      });
  };
  

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-agregar-gastos"
      aria-describedby="modal-agregar-gastos-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width:  400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          maxHeight: "99vh",
          overflowY: "auto" // Agrega una barra de desplazamiento vertical cuando sea necesario
        }}
      >
        <div className="modal">
          <div className="modal-content">
            <div className="p-2 bg-green-600 text-white text-center mb-6 ">AGREGAR GASTO</div>
            <div className="flex-col text-center">
              <div className="mx-auto">
                <TextField
                  id="detalle"
                  label="Detalles"
                  multiline  // Esto permite que el campo sea multilinea
                  rows={4}   // Puedes ajustar el número de filas según tus necesidades
                  value={detalle}
                  fullWidth='true'
                  onChange={e => setDetalle(e.target.value)}
                />
              </div>
              <div className="mt-4 mb-7">
                <TextField
                  id="monto"
                  label="Monto"
                  variant="outlined"
                  fullWidth='true'
                  value={monto}
                  onChange={e => setMonto(e.target.value)}
                />
              </div>
            </div>
            <div>
            <div className="flex">
              <div className="mx-auto">
                <Button
                  variant="contained"
                  sx={{backgroundColor: 'rgb(21 128 61)', '&:hover': {
                  backgroundColor: 'rgb(22 101 52)',  fontWeight:'bold'}}}
                  onClick={
                    // Aquí puedes agregar la lógica para manejar el evento onClick
                    handleGuardar
                  }
                >
                  Guardar
                </Button>
              </div>
              <div className="mx-auto">

              <Button
                variant="contained"
                sx={{backgroundColor: 'rgb(185 28 28)', '&:hover': {
                backgroundColor: 'rgb(153 27 27)',  fontWeight:'bold'}}}
                onClick={() => {
                  // Aquí puedes agregar la lógica para manejar el evento onClick
                  onClose()
                }}
              >
                Cancelar
              </Button>
              </div>
              </div>
           
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AgregarGastosModal;
