import React, { useState, useEffect } from 'react';
import { Button, Modal, Box } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { POST_SEARCH_PAYMENT, POST_DELETE_PAYMENT} from '../../utils/apiUrls'
import { PDFDownloadLink } from '@react-pdf/renderer';
import MyDocument from './PdfPagos';
import { formatearMonedaARS, formatearFechaISO8601, sumarImportes } from '../../utils/funciones';
import { useStyles } from '../../utils/funciones';
import PaymentIcon from '@mui/icons-material/Payment';

const ModalConsulta = ({ open, setOpenModal, inmueble, fechaSeleccionada, numOperacion, montoIntereses, abonaInteres, actualizarTabla, setActualizarTabla}) => {
    const [imprimePdf, setImprimePdf] = useState(false)
    const [gastos, setGastos] = useState(null); // Estado para almacenar la gastos encontrada
    const [totalGastos, setTotalGastos] = useState(0);
    const [montoInteresesSinFormatear, setMontoInteresesSinFormatear] = useState(0)
    const [actualizar, setActualizar] = useState(false)
    const classes = useStyles();

    useEffect(() => {
        const pedirdatos =async () => {
            try {
                // Realizar la solicitud POST al servidor para buscar la gastos
                const response = await fetch(POST_SEARCH_PAYMENT, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                      body: JSON.stringify({ id_inmueble: inmueble.id, fecha:fechaSeleccionada }),
                });
                if (response.ok) {
                  const data = await response.json();
                  //console.log('Gastos encontrados:', data);
                  console.log(data);
                  setGastos(data);
                  // Sumar los montos de los gastos
                } else {
                  console.error('Error al buscar la gastos:', response.status);
                  setGastos(null); 
                }
              } catch (error) {
                console.error('Error en la solicitud:', error);
              }
              
        }
        pedirdatos()
        
    }, [ actualizar  ]);

    const handleClose = () => {
        setActualizarTabla(!actualizarTabla)
        setOpenModal(!setOpenModal)

    };
    setTimeout(() => {
        setImprimePdf(true)
    }, 100);

    const handlePdf = async () =>{
        setActualizar(!actualizar)
    }

    const handleDelete = async () =>{
        try {
            // Realizar la solicitud POST al servidor para buscar la gastos
            const response = await fetch(POST_DELETE_PAYMENT, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
                  body: JSON.stringify({ id_inmueble:inmueble.id, fecha:fechaSeleccionada, gastos:gastos }),
            });
            if (response.ok) {
              const data = await response.json();
              //console.log('Gastos encontrados:', data);
                setActualizar(!actualizar)
                handleClose()
            } else {
              console.error('Error al buscar la gastos:', response.status);
              setGastos(null);

            }
          } catch (error) {
            console.error('Error en la solicitud:', error);
          }
    }

    useEffect(() => {
        let total = 0;
        // Sumar los montos de los gastos
        if (gastos && Array.isArray(gastos)) {
            gastos.forEach(gasto => {
                total += parseFloat(gasto.monto);
            });
            if (abonaInteres) {
                total += montoInteresesSinFormatear;
            }
            setTotalGastos(total);
        }
    }, [gastos, actualizar, abonaInteres, montoInteresesSinFormatear, inmueble.alquiler_precio]);
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width:  800,
                        height: 'h-full' ,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 2,
                    }}
                >
                <div className='flex bg-red-800 '>
                    <div className='flex mx-auto'>
                    <div className='w-full  bg-red-800 text-center text-white p-2 text-2xl'><PaymentIcon sx={{width:'35px', minHeight:'35px'}}/></div>
                    <div className='w-full  bg-red-800 text-center text-white p-2 text-2xl pt-3'>PAGOS</div>
                    </div>
                </div>
                <div className={classes.card}>
                    <div className='text-center p-2 pt-4 text-2xl'>El inmueble ya tiene el pago registrado.</div>
                    <div className='text-center p-2 text-xl'>Pero de todas formas usted puede</div>
                    <div className='flex py-4'>
                        <div className='p-3 mx-auto mr-4 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-md flex  cursor-pointer' onClick={handlePdf}>
                            <PictureAsPdfIcon className='mr-2'/> 
                            {imprimePdf ? 
                                gastos? <PDFDownloadLink document={<MyDocument mesAbono={fechaSeleccionada} numOperacion={numOperacion} montoIntereses={montoIntereses} abonaInteres={abonaInteres} totalGastos={formatearMonedaARS(totalGastos)} inmueble={inmueble} gastos={gastos}/>} fileName={inmueble.direccion + " "+ inmueble.altura + " " + inmueble.piso + "-" +  inmueble.unidad + ".pdf"} mesAbono={null} >
                                {({ blob, url, loading, error }) => (
                                loading ? 
                                'Cargando documento...' : 
                                'Descargar PDF')}
                                </PDFDownloadLink>: null    : null
                        } 
         
                        </div>
                        <div className='p-3 mx-auto ml-4 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-md flex cursor-pointer' onClick={handleDelete}><DeleteOutlineIcon/><p className='pl-2'>Eliminar Pago</p></div>
                    </div>
               </div>
                </Box>
            </Modal>
        </div>
    );
};

export default ModalConsulta;
