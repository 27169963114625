import { makeStyles } from '@mui/styles';

export function convertirFecha(fecha) {
     // Parseamos la fecha en formato ISO 8601 a un objeto Date
  const dateObj = new Date(fecha);

  // Obtenemos el día, el mes y el año
  const dia = dateObj.getUTCDate();
  const mes = dateObj.getUTCMonth() + 1; // Sumamos 1 porque los meses en JavaScript van de 0 a 11
  const anio = dateObj.getUTCFullYear();

  // Formateamos la fecha en el formato "dd/mm/yyyy"
  const fechaFormateada = `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${anio}`;

  return fechaFormateada;
  }

  export function formatearMonedaARS(numero) {
    let numero2 = parseFloat(numero);
    const options = { style: 'currency', currency: 'ARS', minimumFractionDigits: 2, maximumFractionDigits: 2 };
   return numero2.toLocaleString('es-AR', options);
 }

 export function formatearFechaISO8601(fechaISO) {
   const fecha = new Date(fechaISO);
   const día = fecha.getUTCDate().toString().padStart(2, "0");
   const mes = (fecha.getUTCMonth() + 1).toString().padStart(2, "0");
   const año = fecha.getUTCFullYear();
   return `${día}-${mes}-${año}`;
 }
 export function formatearFechaCorta(date) {
  const fecha = new Date(date);
  const día = fecha.getUTCDate().toString().padStart(2, "0");
  const mes = (fecha.getUTCMonth() + 1).toString().padStart(2, "0");
  const año = fecha.getUTCFullYear();
  return `${mes}-${año}`;
}
export const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: `0px 8px 16px ${theme.palette.grey[400]}`,
    transition: 'transform 0.3s ease-in-out',
    maxHeight: '88vh',
    '&:hover': {
      transform: 'scale(1.005)',
    },
    
  },

  title: {
    color: theme.palette.primary.dark,
    borderBottom: `2px solid ${theme.palette.primary.dark}`,
    marginBottom: theme.spacing(2), // Aquí se accede a theme.spacing
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    margin: `${theme.spacing(2)}px 0`, // Aquí se accede a theme.spacing
  },
}));

 export function formatearHora(date) {
  const fecha = new Date(date)
  let hora = fecha.getHours();
  if (hora < 10) {
    hora = '0' + hora
  }
  let minutos = fecha.getMinutes();
  if (minutos < 10) {
    minutos = '0' + minutos
  }
  const hora_pago = hora + ":" + minutos;
  return `${hora_pago}`;
}
 export function sumarImportes(inmueble, intereses) {
   let final = 0
   if (inmueble.precio) {
      final += inmueble.precio;
    }
    if (intereses) {
      final += intereses;
    }
    if (inmueble.montoexpensas) {
      final += inmueble.montoexpensas;
    }
    if (inmueble.agua_monto) {
      final += inmueble.agua_monto;
    }
    if (inmueble.epec_monto) {
      final += inmueble.epec_monto;
    }
    if (inmueble.gas_monto) {
      final += inmueble.gas_monto;
    }
    if (inmueble.cochera_monto) {
      final += inmueble.cochera_monto;
    }
    return final
 }
