import React from "react";
import { useState } from "react";
import Navar from "../utils/Navar";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import TablePropietarios from "../components/propietarios/Tabla";
import AgregarPropiedad from "../components/propiedades/AgregarPropiedad";
import AgregarPropietario from "../components/propietarios/AgregarPropietario";
import { Button } from "@mui/material";
import { useStyles } from "../utils/funciones";
import Title from "../components/propietarios/Title";
import AddIcon from "@mui/icons-material/Add";
const Dashboard = ({ setIsAuthenticated }) => {
  const [openAgregarPropiedad, setOpenAgregarPropiedad] = useState(false);
  const [propiedad, setPropiedad] = useState(false);
  const [actualizar, setActualizar] = useState(false);
  const classes = useStyles();

  const handleAgregarPropiedad = () => {
    setPropiedad();
    setOpenAgregarPropiedad(true);
  };

  return (
    <div className="bg-gray-100  h-screen flex">
      <Navar setIsAuthenticated={setIsAuthenticated} />
      <div className="w-full text-center flex-col mx-auto mt-20 -ml-20  ">
        <div className={classes.card}>
          <div className="p-4">
            <Title />
          </div>
          <div className="font-bold text-3xl  mx-auto cursor-default w-full p-4 -mt-6 ">
            <div className="text-end mb-4">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#000000",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.6)"
                  }
                }}
                startIcon={<AddIcon />}
                onClick={() => {
                  // Aquí puedes agregar la lógica para manejar el evento onClick
                  handleAgregarPropiedad();
                }}
              >
                Agregar Propietario
              </Button>
            </div>
            <TablePropietarios
              actualizar={actualizar}
              setActualizar={setActualizar}
            />
          </div>
        </div>
      </div>
      {openAgregarPropiedad
        ? <AgregarPropietario
            open={openAgregarPropiedad}
            setOpenModal={setOpenAgregarPropiedad}
            actualizar={actualizar}
            setActualizar={setActualizar}
          />
        : null}
    </div>
  );
};

export default Dashboard;
