import React, { useState, useEffect } from "react";
import { BUSCAR_AGREGAR_INMUEBLES_EDIFICIOS, CARGAR_INMUEBLE, ELIMINAR_INMUEBLE } from "../../utils/apiUrls";

const TablaAgregarInmuebles = ({ isOpen, setIsModalOpen, id_edificio }) => {
  const [inmuebles, setInmuebles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedInmuebles, setSelectedInmuebles] = useState([]);

  useEffect(() => {
    const fetchInmuebles = async () => {
      try {
        const response = await fetch(BUSCAR_AGREGAR_INMUEBLES_EDIFICIOS, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ id_edificio })
        });
        const data = await response.json();
        setInmuebles(data.data);
      } catch (error) {
        console.error("Error fetching inmuebles", error);
      }
    };
    fetchInmuebles();
  }, [id_edificio]);

  const handleInmuebleSelect = (inmueble) => {
    if (selectedInmuebles.includes(inmueble.id)) {
      setSelectedInmuebles(selectedInmuebles.filter(id => id !== inmueble.id));
    } else {
      setSelectedInmuebles([...selectedInmuebles, inmueble.id]);
    }
  };

  const handleGuardar = async () => {
    try {
      const response = await fetch(CARGAR_INMUEBLE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ inmuebles: selectedInmuebles, id_edificio })
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error saving inmuebles", error);
    }
  };

  const handleCancelar = () => {
    setSelectedInmuebles([]);
    setIsModalOpen(false);
  };

  const filteredInmuebles = inmuebles.filter(inmueble =>
    (inmueble.direccion + " " + inmueble.altura + " " + inmueble.piso + " " + inmueble.unidad)
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <>
        <div style={{ height: "calc(100vh - 350px)" }} className="overflow-y-auto">
        <div className="sticky top-0 bg-white z-10 mb-4">
          <input
            type="text"
            placeholder="Buscar inmuebles..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div style={{ height: "calc(100vh - 470px)" }} className="overflow-y-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Dirección</th>
                <th className="py-2 px-4 border-b">Seleccionar</th>
              </tr>
            </thead>
            <tbody>
              {filteredInmuebles.map(inmueble => (
                <tr
                  key={inmueble.id}
                  className={`cursor-pointer ${selectedInmuebles.includes(inmueble.id) ? "bg-blue-100" : ""}`}
                  onClick={() => handleInmuebleSelect(inmueble)}
                >
                  <td className="py-2 px-4 border-b">
                    {inmueble.direccion + " " + inmueble.altura + " " + inmueble.piso + " " + inmueble.unidad}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    <input
                      type="checkbox"
                      checked={selectedInmuebles.includes(inmueble.id)}
                      onChange={() => handleInmuebleSelect(inmueble)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-4">
          <button
            type="button"
            onClick={handleGuardar}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Guardar
          </button>
          <button
            type="button"
            onClick={handleCancelar}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Cancelar
          </button>
        </div>
      </div>
    </>
  );
};

export default TablaAgregarInmuebles;
