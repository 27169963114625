import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { useStyles } from "../../utils/funciones";
import { GENERAR_PDF } from "../../utils/apiUrls";

const BuildingCard = ({
  nombre,
  localidad,
  unidades,
  direccion,
  id,
  setId_Edificio,
  setOpenInmuebles,
  setOpenExpensas,
  openExpensas,
  fecha,
  setName
}) => {
  const classes = useStyles();

  const handleOpenInmuebles = () => {
    setId_Edificio(id);
    setOpenInmuebles(true);
  };
  const handleGeneratePDF = async () => {
    const response = await fetch(GENERAR_PDF, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, fecha }),
    });
  
    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
  
      // Crear un enlace para descargar el archivo
      const a = document.createElement("a");
      a.href = url;
  
      // Asignar el nombre del archivo descargado
      let mes = new Date(fecha).getMonth() + 1
      let anio = new Date(fecha).getFullYear() 
      const fileName = `${mes}-${anio} - ${direccion}.pdf`;  // Reemplazar con los valores reales
      a.download = fileName;
  
      // Simular el clic para iniciar la descarga
      document.body.appendChild(a);
      a.click();
  
      // Eliminar el enlace del DOM después de la descarga
      document.body.removeChild(a);
    } else {
      console.error("Error al generar el PDF");
    }
  };
  

  const handleOpenModal = async () => {
    setName(nombre)
    setId_Edificio(id);
    setOpenExpensas(true);
  };

  return (
    <Box className="mx-auto w-full max-w-[350px]">
      <Card className={classes.card} variant="outlined">
        <CardContent>
          <Typography variant="h5" component="div" sx={{ marginBottom: 1 }}>
            <ApartmentIcon sx={{ marginRight: 1 }} /> {nombre}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: 1 }}>
            {localidad}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: 1 }}>
            {direccion}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: 1 }}>
            Unidades: {unidades}
          </Typography>
        </CardContent>
        <Box className="w-full text-center" sx={{ paddingBottom: 2 }}>
          <Box className="flex flex-col items-center">
            <Button
              variant="outlined"
              sx={{ fontWeight: "bold", marginBottom: 2 }}
              color="success"
              onClick={handleOpenInmuebles}
              startIcon={<VisibilityIcon />}
            >
              VER UNIDADES
            </Button>
            <Button
              variant="outlined"
              sx={{ fontWeight: "bold", marginBottom: 2 }}
              color="primary"
              onClick={handleOpenModal}
              startIcon={<AddIcon />}
            >
              Cargar Expensas
            </Button>
            <Button
              variant="outlined"
              sx={{ fontWeight: "bold", marginBottom: 2 }}
              color="secondary"
              onClick={handleGeneratePDF}
              startIcon={<DownloadIcon />}
            >
              Descargar Expensas
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default BuildingCard;
