import React from "react";
import ApartmentIcon from "@mui/icons-material/Apartment";

const Title = ({name, setName}) => {
  return (
    <div className="flex">
      <div className="text-2xl font-semibold mx-auto flex items-center text-center mt-4">
        <ApartmentIcon className="mr-2 text-violet-900" />
        <p className=" text-violet-900 ">{name}</p>
      </div>
    </div>
  );
};
export default Title;
